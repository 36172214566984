import { useForm } from "react-hook-form";
import ValidateEmailModal from "./ValidateEmailModal";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { AuthenticationWizard, ValidateEmailError } from "Pages/Authentication/types";
import { isEmail } from "Utilities/helpers";

interface UseValidateEmailStepProps {
  isLoading: boolean;
  error?: string;
  submitError?: ValidateEmailError;
  canProceed: boolean;
  onChangeWizard: (wizard: AuthenticationWizard, email: string) => void;
  onSubmit: (email: string) => void;
  onEnter: () => void;
  defaultEmail?: string | null;
}

const useValidateEmailStep = ({
  isLoading,
  canProceed,
  error,
  submitError,
  onSubmit,
  onEnter,
  onChangeWizard,
  defaultEmail,
}: UseValidateEmailStepProps): Step<"validate-email"> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t("login_requiredEmail"))
      .test("isValidEmail", t("login_invalidEmail"), function (newValue) {
        if (typeof newValue !== "string") {
          return false;
        }
        return isEmail(newValue);
      }),
  });

  const { control, handleSubmit, formState, getValues } = useForm<{ email: string }>({
    defaultValues: {
      email: defaultEmail || "",
    },
    resolver: yupResolver(schema),
  });

  const handleChangeToSignInWizard = () => {
    const email = getValues("email");
    onChangeWizard("sign-in-wizard", email);
  };

  const handleChangeToSignUpWizard = () => {
    const email = getValues("email");
    onChangeWizard("sign-up-wizard", email);
  };
  return {
    name: "validate-email",
    title: t("login_validate_title"),
    subtitle: t("login_validate_subtitle"),
    component: (
      <ValidateEmailModal
        control={control}
        onSubmit={onEnter}
        error={formState.errors.email?.message || error}
        submitError={submitError}
        onClickCreateAccount={handleChangeToSignUpWizard}
        onClickSignIn={handleChangeToSignInWizard}
      />
    ),
    onSubmit: handleSubmit(({ email }) => {
      onSubmit(email);
    }),
    canProceed,
    isLoading,
    submitError: !!error && !!submitError,
    backDisabled: true,
  };
};

export default useValidateEmailStep;
