import { FormElement } from "UIKit";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import useGetLocations from "Hooks/useGetLocations";
import { formatLocation } from "Utilities/helpers";
import { EolasSelect } from "UIKit/EolasSelect/EolasSelect";
import { LocationBasedFormInputs } from "./components/LocationBasedFormInputs";
import { Dispatch, SetStateAction } from "react";

interface CreateNewSpaceDetailsProps {
  control: Control<{
    spaceName: string;
    organisationName?: string;
    description: string;
    location: {
      label: string;
      value: string;
    };
  }>;
  autoFocus?: boolean;
  isNameValid?: boolean;
  onSubmit: () => void;
  setSpaceLocation: Dispatch<SetStateAction<string>>;
}

const CreateNewSpaceDetails = ({
  control,
  onSubmit,
  isNameValid,
  setSpaceLocation,
}: CreateNewSpaceDetailsProps) => {
  const { t } = useTranslation();
  const { getLocations } = useGetLocations();

  const handleFetchLocations = async (query: string) => {
    const locations = await getLocations(query);
    return locations.map((location) => formatLocation({ location }));
  };

  return (
    <form onSubmit={onSubmit} className="overflow-auto min-h-50vh px-2">
      <FormElement
        required
        id="location"
        control={control}
        label={t("my-medical_personal_details_edit_profile_location")}
      >
        {(fieldProps) => (
          <EolasSelect
            {...fieldProps}
            mode="asynchronous"
            onChange={(selectedOption) => {
              fieldProps.onChange(selectedOption);
              setSpaceLocation(selectedOption.value);
            }}
            placeholder={t("my-medical_personal_details_edit_profile_location_placeholder")}
            isSearchable
            loadSuggestions={handleFetchLocations}
          />
        )}
      </FormElement>

      <LocationBasedFormInputs control={control} isNameValid={isNameValid} />
    </form>
  );
};

export default CreateNewSpaceDetails;
