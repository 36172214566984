import { AnalyticsEvents, BnfPayload, MonographType, PageNames } from "@eolas-medical/core";
import { InnerPageWrapper, Text, Loader } from "UIKit";
import { TitleStrings, Strings } from "./MultiInteractions.strings";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { useListInteractions } from "modules/bnf/data/useListInteractions";
import { useGetInteractionsForCombination } from "modules/bnf/data/useGetInteractionsForCombination";
import { EolasSelect } from "UIKit/EolasSelect/EolasSelect";
import { useGetInteractants } from "modules/bnf/data/useGetInteractants";
import DrugInteraction from "./components/DrugInteraction";
import { SearchSortList } from "shared/components/Lists";
import { trackEvent } from "API/Analytics";
import { useBnfSearchAnalytics } from "../../../hooks/useBnfSearchAnalytics";

const MultiInteractions = ({ type }: { type: MonographType }) => {
  const { drugs, setDrugs } = useListInteractions();

  const {
    interactantsList,
    isInitialLoading: interactantsListLoading,
    error: interactantsListError,
  } = useGetInteractants({
    type,
  });

  const {
    data: interactionsResultsList,
    isInitialLoading: interactionsResultsListLoading,
    error: interactionsResultsListError,
  } = useGetInteractionsForCombination({
    type,
    selectedDrugs: drugs,
  });

  const onBnfSearch = useBnfSearchAnalytics(
    type === "bnf" ? "interactions_adult" : "interactions_child",
  );

  if (interactantsListError) {
    return (
      <InnerPageWrapper>
        <BnfHeader
          type={type}
          title={TitleStrings[type]}
          goBackBehaviourPreference="removeFromPath"
        />
        <div className="my-4 text-center">
          <Text level={1} className="my-4 text-center w-full h-full">
            {Strings.interactantsListError}
          </Text>
        </div>
      </InnerPageWrapper>
    );
  }

  const renderInteractionsComponents = () => {
    if (!drugs.length) {
      return null;
    }
    if (drugs.length === 1) {
      return (
        <Text level={2} className="my-4 text-center">
          {Strings.moreInteractions}
        </Text>
      );
    }
    if (drugs.length > 40) {
      return (
        <Text level={2} className="my-4 text-center">
          {Strings.tooManyInteractions}
        </Text>
      );
    }
    if (interactionsResultsListLoading) {
      return <Loader className="bg-grey-50" size={40} />;
    }
    if (interactionsResultsListError) {
      return (
        <Text level={2} className="my-4 text-center">
          {Strings.interactionsListError}
        </Text>
      );
    }
    if (interactionsResultsList.length === 0) {
      return (
        <Text level={2} className="my-4 text-center">
          {Strings.noInteractions}
        </Text>
      );
    }
    return (
      <div className="flex flex-col justify-center my-6 space-y-4 w-full">
        <SearchSortList
          items={interactionsResultsList}
          isSearchable={false}
          renderItem={(interaction) => (
            <DrugInteraction key={interaction.id} interaction={interaction} />
          )}
        />
      </div>
    );
  };

  return (
    <InnerPageWrapper>
      <BnfHeader
        type={type}
        title={TitleStrings[type]}
        goBackBehaviourPreference="removeFromPath"
        eolasPageName={PageNames.bnfInteractionsSummary}
      />
      <Text level={1} className="my-4 text-center">
        {Strings.selectDisc}
      </Text>
      {interactantsListLoading ? (
        <Loader className="bg-grey-50" size={40} />
      ) : (
        <EolasSelect
          mode="synchronous"
          className="h-full w-full"
          isMulti
          isSearchable
          options={interactantsList ?? []}
          shouldIgnoreAccents={false}
          onChange={(data) => setDrugs([...data])}
          onBlur={() => {
            if (drugs.length > 1) {
              trackEvent<BnfPayload>(AnalyticsEvents.BNF_INTERACTIONS_VIEWED_MULTI_DRUG, { type });
              onBnfSearch(drugs.map((drug) => drug.label).join(", "));
            }
          }}
        />
      )}
      {renderInteractionsComponents()}
    </InnerPageWrapper>
  );
};

export const MultiInteractionsAdult = () => <MultiInteractions type="bnf" />;
export const MultiInteractionsChild = () => <MultiInteractions type="bnfc" />;
