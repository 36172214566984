import React from "react";
import MasterSearchResults from "../MasterSearchResults";
import useKnowledgeSearch from "Components/MasterSearch/hooks/useKnowledgeSearch";
import { MasterSearchResultsParams } from "Components/MasterSearch/types";

const MasterSearchResultsKnowledge = (params: MasterSearchResultsParams) => {
  const { searchValue, searchInput, knowledgeSearchDisabled } = params;
  const { isFetching, knowledgeFiles } = useKnowledgeSearch({
    searchValue,
    knowledgeSearchDisabled,
  });

  return (
    <>
      <MasterSearchResults
        searchLoading={isFetching && searchInput.length > 0 ? true : false}
        searchResult={knowledgeFiles}
        knowledgeSearchDisabled={knowledgeSearchDisabled}
        {...params}
        onClickSearch={(searchText?: string) => {
          params.onClickSearch(searchText);
        }}
        onClearSearch={() => {
          params.onClearSearch();
        }}
      />
    </>
  );
};

export default MasterSearchResultsKnowledge;
