import { useTranslation } from "react-i18next";

import {
  Input,
  Button,
  Title,
  ModalBody,
  FormError,
  FormElement,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
  IconPicker,
  IconButton,
} from "UIKit";
import * as yup from "yup";
import { useState } from "react";
import { ArrowLeftIcon } from "Assets";
import useRemoteIcons from "Hooks/useRemoteIcons";
import { MainSection } from "modules/mainSections/types";
import { UpdateMainSectionDto } from "@eolas-medical/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useEditMainSection from "modules/mainSections/data/useEditMainSection";
import useToggleMainSection from "modules/mainSections/data/useToggleMainSection";
import { modalStore } from "Stores/ModalStore";

export interface EditSectionModalProps {
  section: MainSection;
  onCloseModal(): void;
}

export const EditSectionModal: React.FC<EditSectionModalProps> = ({
  section,
  onCloseModal,
}: EditSectionModalProps) => {
  const [isAddingIcon, setIsAddingIcon] = useState(false);
  const [selectedIconName, setSelectedIconName] = useState(section.iconName);

  const { t } = useTranslation();
  const [selectedIconUrl, setSelectedIconUrl] = useState(
    section.isIconEditable ? section.iconUrl : "",
  );

  const editMainSectionSchema = yup.object().shape({
    id: yup.string().required(),
    description: yup.string().required(),
    name: yup.string().required(),
    icon: yup.string().notRequired(),
  });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      id: section.id ?? "",
      name: section.name ?? "",
      description: section.description ?? "",
      icon: section.iconName ?? "",
    },
    resolver: yupResolver(editMainSectionSchema),
  });

  const { editMainSection, editingMainSection, editMainSectionError, editMainSectionSuccess } =
    useEditMainSection();

  const {
    toggleMainSection,
    togglingMainSection,
    toggleMainSectionSuccess,
    toggleMainSectionError,
  } = useToggleMainSection();

  const { remoteIcons } = useRemoteIcons();

  const handleEditMainSection = ({ id, name, description }: UpdateMainSectionDto) => {
    const updatedMainSectionSection: UpdateMainSectionDto = { id, name, description };
    if (section.isIconEditable) updatedMainSectionSection.icon = selectedIconName;
    editMainSection(updatedMainSectionSection);
  };

  const handleDeactivateMainSection = () => {
    modalStore.openModal({
      icon: "warning",
      name: "confirmDeactivateSection",
      variant: "dialogue",
      title: t("contentManagement_editFeature_deactivate_warning"),
      message: t("contentManagement_editFeature_deactivate_message"),
      onConfirm: () => {
        toggleMainSection({ id: section.id, disabled: !section.disabled });
      },
    });
  };

  if (toggleMainSectionSuccess) {
    return (
      <SuccessModal onComplete={onCloseModal} text="contentManagement_editFeature_deactivated" />
    );
  }

  if (editMainSectionSuccess) {
    return <SuccessModal onComplete={onCloseModal} text="contentManagement_editFeature_updated" />;
  }

  if (isAddingIcon) {
    return (
      <InnerModalWrapper>
        <Title level={5} className="flex justify-center pt-12">
          {t("contentManagement_editFeature")}
        </Title>

        <ModalBody>
          <div className="-mt-12">
            <IconButton
              className="my-4 mx-auto"
              icon={<ArrowLeftIcon />}
              onClick={() => setIsAddingIcon(false)}
            />
            <IconPicker
              iconsList={remoteIcons}
              defaultShowIcons
              iconURL={selectedIconUrl || ""}
              iconContainerClassname={`
          h-70vh sm:h-60vh p-2
        `}
              iconListClassname={"p-2"}
              onClick={(icon) => {
                setSelectedIconUrl(icon.url);
                setSelectedIconName(icon.fileName);
                setIsAddingIcon(false);
              }}
              label={t("fileWithSearch_addNew_section_icon")}
            />
          </div>
        </ModalBody>
      </InnerModalWrapper>
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex-row">
        <Title level={5} className="ml-4">
          {t("contentManagement_editFeature")}
        </Title>
      </ModalHeader>

      <ModalBody className="overflow-y-auto max-h-70vh">
        <form className="space-y-4" onSubmit={handleSubmit(handleEditMainSection)}>
          <FormElement
            required
            id="name"
            control={control}
            labelClassName="font-semibold"
            label={t("contentManagement_editFeature_name")}
          >
            {(field) => (
              <Input
                {...field}
                disabled={editingMainSection}
                placeholder={t("contentManagement_editFeature_placeholder")}
              />
            )}
          </FormElement>

          <FormElement
            required
            control={control}
            id="description"
            labelClassName="font-semibold"
            label={t("contentManagement_editFeature_description")}
          >
            {(field) => (
              <Input
                {...field}
                disabled={editingMainSection}
                placeholder={t("contentManagement_editFeature_placeholder")}
              />
            )}
          </FormElement>

          {selectedIconUrl ? (
            <FormElement required id="icon" control={control} labelClassName="font-semibold">
              {() => (
                <div>
                  <IconPicker
                    iconURL={selectedIconUrl || ""}
                    onClick={() => {
                      return;
                    }}
                    iconContainerClassname="h-50vh"
                    onOpen={() => setIsAddingIcon(true)}
                    iconListClassname="pr-4 sm:pr-12 pb-4"
                    label={t("section_icon")}
                  />
                </div>
              )}
            </FormElement>
          ) : null}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Button
              color="red"
              type="button"
              variant="outline"
              onClick={handleDeactivateMainSection}
              isLoading={togglingMainSection}
            >
              {t("contentManagement_editFeature_deactivate")}
            </Button>
            <Button
              color="blue"
              type="submit"
              variant="solid"
              disabled={!formState.isValid}
              isLoading={editingMainSection}
            >
              {t("updateSection_button")}
            </Button>
          </div>

          <FormError error={editMainSectionError} />
          <FormError error={toggleMainSectionError} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default EditSectionModal;
