import React, { useMemo, useRef, useState } from "react";
import { SearchIcon } from "Assets";
import { Props, CustomControlProps, CustomSelectStyles } from "./EolasSelect.types";
import Select, {
  GroupBase,
  components,
  Props as RSProps,
  SelectInstance,
  OnChangeValue,
  ActionMeta,
  OptionsOrGroups,
  createFilter,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { isDev } from "Utilities/helpers";
import debounce from "lodash/debounce";

/**
 * EolasSelect is a wrapper around react-select and react-select/async that provides a consistent
 * look and feel for selects across the app.
 * It also provides a consistent way to handle asynchronous selects and implements a debounce
 */
export const EolasSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group>,
) => {
  const {
    LeftIconComponent: LeftIconProp,
    isSearchable = false,
    mode,
    shouldIgnoreAccents = true,
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<SelectInstance<Option, IsMulti, Group>>(null);

  const LeftIconComponent = isSearchable ? SearchIcon : LeftIconProp;

  let placeholder = isSearchable
    ? t("component_eolasSelect_placeholder_search")
    : t("component_eolasSelect_placeholder");

  // This needs to be inline to grab the generics
  const customSelectStyles: CustomSelectStyles<Option, IsMulti, Group> = React.useMemo(
    () => ({
      control: (baseStyles) => ({
        ...baseStyles,
        borderRadius: "8px",
        boxShadow: "none",
        borderColor: "#EBEFF1",
        "&:hover": {
          borderColor: "#C2D5FA",
        },
      }),
      dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
        transition: "250ms",
      }),
      multiValue: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#f5f7f8",
        color: "#0D0C22",
        borderRadius: "44px",
        padding: "4px",
      }),
    }),
    [],
  );

  const components: RSProps<Option, IsMulti, Group>["components"] = useMemo(() => {
    if (!LeftIconComponent) {
      return undefined;
    }
    return {
      Control: (props) => <Control {...props} LeftIconComponent={LeftIconComponent} />,
    };
  }, [LeftIconComponent]);

  const handleChange = (
    newValue: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>,
  ) => {
    if (props.onChange) {
      props.onChange(newValue, actionMeta);
    }
    if (actionMeta.action === "clear" && props.isMulti) {
      setIsOpen(false);
    }
  };

  if (mode === "asynchronous") {
    const handleLoadOptions = (
      inputValue: string,
      callback: (options: OptionsOrGroups<Option, Group>) => void,
    ) => {
      if (props.loadSuggestions) {
        props.loadSuggestions(inputValue).then((resp) => callback(resp));
      } else if (props.loadOptions) {
        props.loadOptions(inputValue, callback);
      }

      if (!props.loadSuggestions && isSearchable) {
        if (isDev()) {
          console.warn(
            "EolasSelect: You are using a searchable asynchronous select without a loadSuggestions prop. Please provide a loadSuggestions prop to fetch data asynchronously.",
          );
        }
      } else if (!isSearchable && !props.loadOptions) {
        if (isDev()) {
          console.warn(
            "EolasSelect: You are using an asynchronous select without a loadOptions prop. Please provide a loadOptions prop to fetch data asynchronously.",
          );
        }
      }
    };

    const debouncedLoadSuggestions = debounce(handleLoadOptions, props.debounceMs ?? 500);

    return (
      <AsyncSelect
        ref={selectRef}
        placeholder={placeholder}
        {...props}
        loadOptions={isSearchable ? debouncedLoadSuggestions : handleLoadOptions}
        styles={customSelectStyles}
        onMenuClose={() => setIsOpen(false)}
        onMenuOpen={() => setIsOpen(true)}
        menuIsOpen={isOpen}
        isSearchable={isSearchable}
        components={components}
        closeMenuOnSelect={!props.isMulti}
        filterOption={shouldIgnoreAccents ? undefined : createFilter({ ignoreAccents: false })}
      />
    );
  }

  let SelectComponent: CreatableSelect | Select = Select;

  if (props.shouldAllowCreate) {
    SelectComponent = CreatableSelect;
    if (isSearchable) {
      placeholder = t("component_eolasCreatableSelect_placeholder_search");
    }
  }

  return (
    <SelectComponent
      ref={selectRef}
      placeholder={placeholder}
      {...props}
      styles={customSelectStyles}
      onMenuClose={() => setIsOpen(false)}
      onMenuOpen={() => setIsOpen(true)}
      menuIsOpen={isOpen}
      isSearchable={isSearchable}
      components={components}
      closeMenuOnSelect={!props.isMulti}
      onChange={handleChange}
      filterOption={shouldIgnoreAccents ? undefined : createFilter({ ignoreAccents: false })}
      className="px-2"
    />
  );
};

const Control = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  LeftIconComponent,
  ...props
}: CustomControlProps<Option, IsMulti, Group>) => {
  const iconClass = "h-5 w-5 ml-2 sm:inline cursor-pointer";

  return (
    <components.Control {...props}>
      <LeftIconComponent className={iconClass} />
      {children}
    </components.Control>
  );
};
