import React from "react";
import { ProfileVerificationStatus } from "@eolas-medical/core";
import { getVerificationStatusClass } from "Pages/MeTab/helpers";
import { Avatar, Button, IconText, Text } from "UIKit";
import { useTranslation } from "react-i18next";
import { ProfileStatusLabel } from "Pages/MeTab/types";
import { twMerge } from "tailwind-merge";

type ProfileDetailsProps = {
  firstName: string;
  lastName: string;
  imageUrl: string;
  verificationStatus?: ProfileVerificationStatus;
  onClick: () => void;
};

const ProfileDetails = ({
  firstName,
  lastName,
  imageUrl,
  verificationStatus,
  onClick,
}: ProfileDetailsProps) => {
  const { t } = useTranslation();

  const { icon, className } = getVerificationStatusClass(verificationStatus);

  return (
    <div className="flex">
      <div className="h-40 w-40 md:h-56 md:w-56 rounded-md relative flex justify-center items-center mx-auto border-6 border-white bg-whit shadow-md">
        <Avatar
          label={`${firstName} ${lastName}`}
          showInitials
          className="text-4xl md:text-6xl rounded-md truncate"
          imageUrl={imageUrl}
        />
        {verificationStatus ? (
          <div onClick={onClick} className="absolute bottom-0 overflow-hidden">
            <div className="bg-white rounded-t-2xl justify-center h-10 p-4 flex items-center">
              <IconText
                level={3}
                className={twMerge(className, "bg-transparent translate-y-1 justify-center")}
                icon={icon}
              >
                {ProfileStatusLabel[verificationStatus]}
              </IconText>
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col justify-center items-start ml-8">
        <Text level={1}>{t("my-medical_dashboard_greeting")}</Text>
        <p className="text-3xl ">{`${firstName} ${lastName}`}</p>
        <Button
          color="grey"
          variant="ghost"
          className="hover:bg-opacity-50 mt-6"
          onClick={onClick}
          iconLeft="EditPencilIcon"
          size="sm"
        >
          <Text level={1} className="ml-2 font-bold">
            {t("my-medical_personal_details_edit_profile")}
          </Text>
        </Button>
      </div>
    </div>
  );
};

export default ProfileDetails;
