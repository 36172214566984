import { useCallback, useState } from "react";
import { fuseSearch } from "./fuseSearch";
import { UseLocalSearchProps } from "./types";
import { useInitSearchBox } from "Hooks/useSearchboxMobx";
import { useMutation } from "@tanstack/react-query";
import { localSearchStore } from "Stores/localSearch/localSearch.store";

type Props<T extends object> = {
  analyticsCallback?: (searchQuery: string, noHits?: number) => void;
} & (
  | ({ mode?: "fuse" } & UseLocalSearchProps<T>)
  | { mode: "callback"; data: T[]; searchCallback: (text: string) => Promise<T[]> }
);
export const useLocalMobxSearch = <T extends { id: string }>(props: Props<T>) => {
  const [results, setResults] = useState<T[] | null>(null);
  const { searchInstanceId } = useInitSearchBox();

  const { mutate, isLoading, reset } = useMutation({
    mutationKey: [searchInstanceId, "useLocalSearch", props.mode],
    mutationFn: async (text: string) => {
      let newResults: T[];
      if (props.mode === "callback") {
        newResults = await props.searchCallback(text);
      } else {
        localSearchStore.setTextToHighlight(text);
        ({ results: newResults } = fuseSearch(text, props.data, props.keysToSearch));
      }
      props.analyticsCallback?.(text, newResults.length);

      setResults(newResults);
    },
  });

  const handleSearchClick = useCallback(
    async (text: string) => {
      mutate(text);
    },
    [mutate],
  );

  const handleClearSearch = useCallback(() => {
    setResults(null);
    reset();
  }, [reset]);

  return {
    handleSearchClick,
    searchInstanceId,
    handleClearSearch,
    searchResults: results ?? [],
    isSearchActive: !!results,
    isSearchLoading: isLoading,
  };
};
