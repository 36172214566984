import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import localforage from "localforage";

import { EolasFile, isDefined } from "@eolas-medical/core";
import { getItem, deleteItem, addItem, deleteDb, iterateOverAllItems } from "./db";
import { formatSearchString } from "Stores/localSearch/localSearch.functions";
import { EolasContentMapFile, EolasFileSummary } from "./types";
import { makeContentMapIterationCallback, makeIterationCallback } from "./helpers";
import { SpaceUser } from "clients/departments/types";

class ContentDbStore {
  constructor() {
    makeObservable(this);
    this.isHydrated = (async () => {
      await makePersistable(this, {
        storage: localforage,
        name: "contentDbStore",
        properties: ["lastUpdatedTimestamp"],
        stringify: false,
      });
      return true;
    })();
  }

  isHydrated: Promise<boolean>;

  @observable lastUpdatedTimestamp: string | null = null;

  @observable isAddingChecklistFiles = false;

  @action setLastUpdatedTimestamp = (newValue: string | null) => {
    this.lastUpdatedTimestamp = newValue;
  };

  @action setIsAddingChecklistFiles(newValue: boolean) {
    this.isAddingChecklistFiles = newValue;
  }

  getItem = getItem;

  deleteItem = deleteItem;

  getAllItemsAsSummaries = async () => {
    const summaries: EolasFileSummary[] = [];

    const callback = makeIterationCallback(summaries);

    await iterateOverAllItems({ callback });

    return summaries;
  };

  getAllEnrichedContent = async (spaceUsers: SpaceUser[], spaceId: string) => {
    const summaries: EolasContentMapFile[] = [];
    const callback = makeContentMapIterationCallback({ summaries, spaceUsers, spaceId });

    await iterateOverAllItems({ callback });

    return summaries;
  };

  addItem = async (item: EolasFile) => {
    const formattedItem: EolasFile = {
      ...item,
      searchField: formatSearchString(item.searchField ?? ""),
    };
    await addItem(formattedItem);
    return formattedItem;
  };

  getMultipleItems = async <T extends { id: string }>(references: T[]) => {
    const allResponses = await Promise.all(references.map(({ id }) => this.getItem(id)));
    return allResponses.filter(isDefined);
  };

  deleteMultipleItems = async <T extends { id: string }>(references: T[]) => {
    await Promise.all(references.map(({ id }) => this.deleteItem(id)));
  };

  addMultipleItems = async (items: EolasFile[]) => {
    return await Promise.all(items.map((eolasFile) => this.addItem(eolasFile)));
  };

  clearStore = () => {
    this.setLastUpdatedTimestamp(null);
    this.isAddingChecklistFiles = false;
    deleteDb();
  };
}

export let contentDbStore: ContentDbStore;

export const initialiseContentDbStore = async () => {
  contentDbStore = new ContentDbStore();
  await contentDbStore.isHydrated;
};
