import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  CommunityLevelSection,
  GeneralCategory,
  NiceSummaryItem,
  PageNames,
} from "@eolas-medical/core";
import { NavButton, PageTitle, InnerPageWrapper, Modal } from "UIKit";
import { useMainSectionVisit } from "Hooks";
import { SearchSortList } from "shared/components/Lists";
import { useGetNiceGuidelinesHome } from "../hooks/useNiceGuidelinesHome";
import { NiceGuidelineTile } from "../components/NiceGuidelineTile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";

export const NiceGuidelinesHome = () => {
  useMainSectionVisit({
    mainSectionId: CommunityLevelSection.niceGuidelines,
  });

  const history = useHistory();

  const {
    isLoading,
    searchText,
    onChangeText,
    onClearSearch,
    onClickSearch,
    handleClickTile,
    type,
    itemsToDisplay,
  } = useGetNiceGuidelinesHome({
    history,
  });

  const { t } = useTranslation();
  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();
  const renderItem = useCallback(
    (item: GeneralCategory | NiceSummaryItem) => {
      return (
        <NiceGuidelineTile
          item={item}
          onClick={() => {
            handleClickTile(item);
          }}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      );
    },
    [handleClickTile, showRemoveFavouriteModal],
  );

  return (
    <>
      <InnerPageWrapper>
        <Modal open={isFavouriteModalOpen} onClose={closeFavouriteModal}>
          {removeFavouriteModal}
        </Modal>
        <PageTitle
          title={t("community_niceGuidelines_title")}
          eolasPageName={PageNames.NiceGuidelinesHome}
        />
        <NavButton onClick={() => history.goBack()} />
        {type === "categories" ? (
          <SearchSortList<GeneralCategory>
            value={searchText}
            placeholderSearchText={t("community_niceGuidelines_search_all")}
            items={itemsToDisplay}
            isSearchable
            isLoading={isLoading}
            searchType="click"
            onSearchInputChange={onChangeText}
            onClearSearch={onClearSearch}
            onClickSearch={onClickSearch}
            renderItem={renderItem}
          />
        ) : (
          <SearchSortList<NiceSummaryItem>
            value={searchText}
            placeholderSearchText={t("community_niceGuidelines_search_all")}
            items={itemsToDisplay}
            isSearchable
            isLoading={isLoading}
            searchType="click"
            onSearchInputChange={onChangeText}
            onClearSearch={onClearSearch}
            onClickSearch={onClickSearch}
            renderItem={renderItem}
          />
        )}
      </InnerPageWrapper>
    </>
  );
};
