import React from "react";

import { Title } from "../../Typography";
import { twMerge } from "tailwind-merge";
import { observer } from "mobx-react-lite";
import { usePageAnalytics } from "Hooks/usePageAnalytics";

export interface PageTitleProps {
  title: string;
  subTitle?: string;
  className?: string;
  titleClass?: string;
  subTitleClass?: string;
  isPageAnalyticsEnabled?: boolean;
  eolasPageName?: string;
}

export const PageTitle: React.FC<PageTitleProps> = observer(
  ({
    title,
    subTitle,
    titleClass = "text-2xl lg:text-5xl",
    subTitleClass,
    className = "",
    isPageAnalyticsEnabled = true,
    eolasPageName,
    ...props
  }: PageTitleProps) => {
    usePageAnalytics({ title, eolasPageName, isEnabled: isPageAnalyticsEnabled });

    return (
      <div className={twMerge("justify-start text-start space-y-2 w-full", className)} {...props}>
        {subTitle ? (
          <Title level={9} className={twMerge("text-black", subTitleClass)}>
            {subTitle}
          </Title>
        ) : null}

        <Title level={3} className={titleClass} data-testid="pageTitle">
          {title}
        </Title>
      </div>
    );
  },
);
