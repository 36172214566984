import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { OrganisationLevelSection, PageNames } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";

import { AddButton, Modal, PageTitle, InnerPageWrapper } from "UIKit";

import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";
import { NavBackButton } from "shared/components/LogicComponents";

export const OrgPatientLeaflets = observer(() => {
  useMainSectionVisit({ mainSectionId: OrganisationLevelSection.orgPatientLeaflets });
  const { isAdmin } = useSpacesContext();
  const { t } = useTranslation();

  const { spaceSection } = useSpaceSection(OrganisationLevelSection.orgPatientLeaflets);

  const { modal, onAddSection, onCloseModal, onEditSection, onDeleteSection } =
    useSectionManagement(spaceSection.id);

  return (
    <>
      <InnerPageWrapper>
        <PageTitle
          title={spaceSection.name ?? t(OrganisationLevelSection.orgPatientLeaflets)}
          eolasPageName={PageNames.miniAppSectionList(OrganisationLevelSection.orgPatientLeaflets)}
        />
        <NavBackButton modePreference="removeFromPath" />

        {isAdmin ? (
          <AddButton onClick={onAddSection} data-testid="add-new-subSection">
            {t("addNewSection_button")}
          </AddButton>
        ) : null}
        <SectionList
          isAdmin={isAdmin}
          sectionID={spaceSection.id}
          onEdit={onEditSection}
          onAddItem={onAddSection}
          onDelete={onDeleteSection}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={onCloseModal}>
        {modal}
      </Modal>
    </>
  );
});
