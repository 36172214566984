import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { AppLevelSection, EolasFile, PageNames, sectionStore } from "@eolas-medical/core";

import { DeleteFileModal, EolasFileList } from "Components";
import { useMainSectionVisit } from "Hooks";
import { SubSectionRouteParams } from "Utilities/types";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";

import { useEducationPortal } from "../hooks/useEducationPortal";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { AddItemWithExpirationModal } from "Pages/Spaces/components/AddEolasFile";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { VideoComponent } from "../components/VideoComponent";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

export const EducationPortalSection: React.FC<RouteComponentProps<SubSectionRouteParams>> =
  observer(({ match: { url } }) => {
    const { t } = useTranslation();

    const { spaceSubSection } = useSpaceSubSection(AppLevelSection.educationPortal);

    useMainSectionVisit({
      mainSectionId: AppLevelSection.educationPortal,
      subSectionId: spaceSubSection.subSectionId,
    });

    const { isShareFileEnabled, isShareModalOpen, closeShareModal, shareModal, handleOnShareFile } =
      useShareFile();

    const { isAdmin } = useSpacesContext();

    const { modal, error, setModal, videoState, setVideoState, onCloseModal, onSelectFile } =
      useEducationPortal();
    const {
      isFavouriteModalOpen,
      closeFavouriteModal,
      removeFavouriteModal,
      showRemoveFavouriteModal,
    } = useRemoveFavouriteModal();
    const onAddModal = useCallback(() => {
      setModal(
        <AddItemWithExpirationModal
          subSectionId={spaceSubSection.subSectionId}
          onCloseModal={onCloseModal}
          mainSectionID={AppLevelSection.educationPortal}
        />,
      );
    }, [onCloseModal, spaceSubSection, setModal]);

    const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

    const handleCloseModal = () => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
      onCloseModal();
    };

    const onEditItem = useCallback(
      (file: EolasFile) => {
        setModal(
          <AddItemWithExpirationModal
            file={file}
            subSectionId={spaceSubSection.subSectionId}
            onCloseModal={onCloseModal}
            mainSectionID={AppLevelSection.educationPortal}
          />,
        );
      },
      [onCloseModal, spaceSubSection, setModal],
    );

    const onDeleteItem = useCallback(
      (file: EolasFile) => {
        setModal(<DeleteFileModal onCloseModal={onCloseModal} file={file} />);
      },
      [setModal, onCloseModal],
    );

    return (
      <InnerPageWrapper>
        <FileBreadcrumbs
          shouldShowSpaceName={true}
          mainSectionId={
            sectionStore.getChildReferenceByMainSectionType(AppLevelSection.educationPortal)?.id
          }
          parentId={spaceSubSection.subSectionId}
        />
        <PageTitle
          title={spaceSubSection.subSectionName ?? ""}
          eolasPageName={PageNames.miniAppFilesList(AppLevelSection.educationPortal)}
        />

        <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

        {isAdmin ? (
          <AddButton onClick={onAddModal} data-testid="add-new-file">
            {t("general_add_new_file")}
          </AddButton>
        ) : null}

        {videoState ? (
          <VideoComponent videoState={videoState} setVideoState={setVideoState} />
        ) : null}

        {error ? <span className="text-red text-center">{t("general_file_error")}</span> : null}

        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditItem}
          onAddItem={onAddModal}
          onDelete={onDeleteItem}
          subSectionId={spaceSubSection.subSectionId}
          onSelectFile={onSelectFile}
          mainSectionId={AppLevelSection.educationPortal}
          onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          {modal || shareModal || removeFavouriteModal}
        </Modal>
      </InnerPageWrapper>
    );
  });
