import { Step } from "UIKit";
import NewSpaceInfo from "./NewSpaceInfo";

interface useNewSpaceInfoStepProps {
  isLoading?: boolean;
}

export const useNewSpaceInfoStep = ({
  isLoading,
}: useNewSpaceInfoStepProps): Step<"newSpaceInfo"> => {
  return {
    name: "newSpaceInfo",
    component: <NewSpaceInfo />,
    isLoading,
    backDisabled: true,
  };
};
