import { useGetBnfSearchParams } from "../../hooks/useGetBnfSearchParams";
import { BnfLabelPill } from "../BnfPill/BnfLabelPill";
import { PageTitle } from "UIKit";
import { MonographType } from "@eolas-medical/core";
import { useCheckBnfAccessModal } from "../../hooks/useCheckBnfAccessModal";
import { BackButtonMode, NavBackButton } from "shared/components/LogicComponents/BackNavButton";

export const BnfHeader = ({
  title: overrideTitle,
  type,
  eolasPageName,
  goBackBehaviourPreference,
  overrideGoBackCallback,
}: {
  type: MonographType;
  goBackBehaviourPreference: BackButtonMode;
  title?: string | null;
  overrideGoBackCallback?: () => void;
  eolasPageName?: string;
}) => {
  const { title } = useGetBnfSearchParams();
  const { modal } = useCheckBnfAccessModal();

  return (
    <>
      <div className="flex flex-col items-center space-y-4">
        <div className="flex flex-col space-x-1 items-center justify-center">
          <PageTitle title={overrideTitle ?? title ?? ""} eolasPageName={eolasPageName} />
          <BnfLabelPill type={type} />
        </div>
        <NavBackButton
          overrideGoBackCallback={overrideGoBackCallback}
          modePreference={goBackBehaviourPreference}
        />
      </div>
      {modal}
    </>
  );
};
