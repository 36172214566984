import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

import { Text } from "../Typography";
import { FormLabel } from "../FormElements";

import { SectionIcon } from "./SectionIcon";

import SVG from "react-inlinesvg";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import { SearchBox } from "UIKit/SearchBox/SearchBox";
import { Icon } from "./types";
import { ClinicalDocumentsIcon } from "Assets";

export interface IconPickerProps {
  iconsList?: Icon[];
  label?: string;
  onOpen?(): void;
  iconURL?: string;
  required?: boolean;
  disabled?: boolean;
  defaultShowIcons?: boolean;
  iconListClassname?: string;
  iconContainerClassname?: string;
  onClick(icon: Icon): void;
}

export const IconPicker: React.FC<IconPickerProps> = ({
  iconsList = [],
  iconURL,
  disabled,
  required,
  iconListClassname,
  label = "Select Icon",
  iconContainerClassname,
  defaultShowIcons = false,
  onOpen,
  onClick,
}) => {
  const { t } = useTranslation();

  const { searchInput, searchResult, onSetSearchInput } = useLocalSearch({
    data: iconsList,
    // Cast to any required as mixed types given to this component
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keysToSearch: ["name", "keywords"] as any,
  });

  const [showIcons, setShowIcons] = useState(defaultShowIcons);

  const onSelectIcon = useCallback(
    (icon: Icon) => () => {
      setShowIcons(false);
      onClick(icon);
    },
    [setShowIcons, onClick],
  );

  const onOpenIconList = useCallback(() => {
    if (typeof onOpen === "function") {
      onOpen();
    } else {
      setShowIcons(true);
    }
  }, [onOpen, setShowIcons]);

  if (showIcons) {
    if (!iconsList.length) {
      return (
        <div className="flex justify-center">
          <Text level={1} className="text-red-500">
            {t("icon_error")}
          </Text>
        </div>
      );
    }
    return (
      <div
        className={tw(`
          flex flex-col space-y-2
          ${iconContainerClassname}
        `)}
        data-testid="icon-picker-icons"
      >
        <div className="mb-4">
          <SearchBox
            value={searchInput}
            placeholder="Search icon..."
            onChangeText={(text) => onSetSearchInput(text)}
            className="w-full h-12 rounded-sm"
            data-testid="add-section-input"
            showIcon={false}
          />
        </div>
        <div className="mb-3">
          <FormLabel htmlFor="" required={required}>
            {label}
          </FormLabel>
        </div>
        <div
          data-testid="iconPickerList"
          className={tw(`
            grid 
            grid-cols-7 
            md:grid-cols-8 
            2xl:grid-cols-9 
            h-auto overflow-y-auto
            ${iconListClassname}
          `)}
        >
          {searchResult.map(({ name, fileName, url }: Icon) => {
            return (
              <button
                key={fileName}
                type="button"
                data-testid={`icon-button-${name}`}
                className="rounded-lg hover:bg-grey-hover"
                onClick={onSelectIcon({ name, fileName, url })}
              >
                <SVG src={url} width={28} height={28} className="p-3 w-full h-full" />
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center space-y-2">
      <FormLabel htmlFor="" required={required}>
        {label}
      </FormLabel>

      <div
        data-testid="icon-picker-placehoder"
        onClick={onOpenIconList}
        className={`
          flex items-center justify-center rounded-full h-40 w-40 relative
          ${
            disabled
              ? "cursor-not-allowed bg-grey-100 opacity-50"
              : "cursor-pointer bg-grey-200 opacity-100"
          }
        `}
      >
        {!iconURL ? <ClinicalDocumentsIcon /> : <SectionIcon size={64} iconURL={iconURL} />}

        {!disabled ? (
          <div
            className={
              "flex justify-center items-center rounded-full absolute inset-0 bg-grey-600 group bg-opacity-0 hover:bg-opacity-80"
            }
          >
            <Text level={2} className="text-white opacity-0 group-hover:opacity-100">
              {t("iconPicker_change_icon")}
            </Text>
          </div>
        ) : null}
      </div>
    </div>
  );
};
