import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { EolasFile, OrganisationLevelSection, PageNames, sectionStore } from "@eolas-medical/core";

import { DeleteFileModal, EolasFileList, VideoPlayer } from "Components";
import { useMainSectionVisit } from "Hooks";
import { SubSectionRouteParams } from "Utilities/types";
import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";

import { useLearningManagement } from "../hooks/useLearningManagement";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSubSection from "modules/spaces/data/useSpaceSubSection";
import { AddItemWithExpirationModal } from "Pages/Spaces/components/AddEolasFile";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

export const LearningManagementSection: React.FC<RouteComponentProps<SubSectionRouteParams>> =
  observer(({ match: { url } }) => {
    const { t } = useTranslation();

    const { spaceSubSection } = useSpaceSubSection(OrganisationLevelSection.learningManagement);

    useMainSectionVisit({
      mainSectionId: OrganisationLevelSection.learningManagement,
      subSectionId: spaceSubSection.subSectionId,
    });
    const { isShareFileEnabled, isShareModalOpen, closeShareModal, shareModal, handleOnShareFile } =
      useShareFile();
    const {
      isFavouriteModalOpen,
      closeFavouriteModal,
      removeFavouriteModal,
      showRemoveFavouriteModal,
    } = useRemoveFavouriteModal();

    const { isAdmin } = useSpacesContext();

    const {
      modal,
      error,
      video,
      setModal,
      videoRef,
      onCloseModal,
      onSelectFile,
      onCloseVideo,
      onVideoProgress,
    } = useLearningManagement();

    const onAddModal = useCallback(() => {
      setModal(
        <AddItemWithExpirationModal
          subSectionId={spaceSubSection.subSectionId}
          onCloseModal={onCloseModal}
          mainSectionID={OrganisationLevelSection.learningManagement}
        />,
      );
    }, [onCloseModal, spaceSubSection, setModal]);

    const handleCloseModal = () => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
      onCloseModal();
    };
    const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

    const onEditItem = useCallback(
      (file: EolasFile) => {
        setModal(
          <AddItemWithExpirationModal
            file={file}
            subSectionId={spaceSubSection.subSectionId}
            onCloseModal={onCloseModal}
            mainSectionID={OrganisationLevelSection.learningManagement}
          />,
        );
      },
      [onCloseModal, spaceSubSection, setModal],
    );

    const onDeleteItem = useCallback(
      (file: EolasFile) => {
        setModal(<DeleteFileModal onCloseModal={onCloseModal} file={file} />);
      },
      [setModal, onCloseModal],
    );

    return (
      <InnerPageWrapper>
        <FileBreadcrumbs
          shouldShowSpaceName={true}
          mainSectionId={
            sectionStore.getChildReferenceByMainSectionType(
              OrganisationLevelSection.learningManagement,
            )?.id
          }
          parentId={spaceSubSection.subSectionId}
        />
        <PageTitle
          title={spaceSubSection.subSectionName ?? ""}
          eolasPageName={PageNames.miniAppFilesList(OrganisationLevelSection.learningManagement)}
        />

        <NavButton to={url.replace(`/${spaceSubSection.encodedSubSectionId}`, "")} />

        {isAdmin ? (
          <AddButton onClick={onAddModal} data-testid="add-new-file">
            {t("general_add_new_file")}
          </AddButton>
        ) : null}

        {video.url ? (
          <div ref={videoRef}>
            <VideoPlayer
              url={video.url}
              title={video.name}
              closeVideo={onCloseVideo}
              onVideoProgress={onVideoProgress}
            />
          </div>
        ) : null}

        {error ? <span className="text-red text-center">{t("general_file_error")}</span> : null}

        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditItem}
          onAddItem={onAddModal}
          onDelete={onDeleteItem}
          subSectionId={spaceSubSection.subSectionId}
          onSelectFile={onSelectFile}
          mainSectionId={OrganisationLevelSection.learningManagement}
          onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          {modal || shareModal || removeFavouriteModal}
        </Modal>
      </InnerPageWrapper>
    );
  });
