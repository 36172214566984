import { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useLaunchDarkly } from "Contexts";
import { BaseAppRoute, LDFlagNames } from "Utilities/types";
import { useEolasNavigation } from "Components/Navigation/hooks";

import useSpacesContext from "modules/spaces/context/useSpacesContext";

import { SelectSpace } from "./pages/SelectSpace/SelectSpace";
import { SpaceRoutes } from "./pages/Space/SpaceRoutes";
import { SpaceAdminRoutes } from "./pages/Space/SpaceAdminRoutes";
import { OrganisationAdminRoutes } from "./pages/Organisation/OrganisationAdminRoutes";
import { OrganisationRoutes } from "./pages/Organisation/OrganisationRoutes";
import { getOrganisationRoute, getSpaceRoute } from "./helpers";
import { observer } from "mobx-react-lite";
import { useRunOnMountUnmount } from "Hooks";
import { webStore } from "Stores/WebStore";
import { userStore } from "@eolas-medical/core";
import { SESSION_TIMEOUT } from "modules/session";

const SpacesRoutes = observer(() => {
  const { hasOrganisationTab, hasSpacesTab } = useEolasNavigation();
  const { isAdmin, isInSpace, selectedSpace } = useSpacesContext();
  const { flags } = useLaunchDarkly();
  const showAdminRoutes = flags[LDFlagNames.READ_ONLY_ACTIVATED] ? false : isAdmin;

  const redirectRoute = useMemo(() => {
    return hasSpacesTab ? getSpaceRoute(selectedSpace) : getOrganisationRoute(selectedSpace);
  }, [hasSpacesTab, selectedSpace]);

  useRunOnMountUnmount({
    onMount: () => {
      const { lastAppFetchTimestamp } = webStore;
      if (!userStore.userSession.isLoggedIn || !lastAppFetchTimestamp) {
        return;
      }
      const lastFetch = new Date(lastAppFetchTimestamp).getTime();
      const now = new Date().getTime();
      if (now - lastFetch > SESSION_TIMEOUT) {
        userStore.setLoggedStatus(false);
        webStore.setAppLastFetchTimestamp(null);
      }
    },
  });

  if (isInSpace) {
    if (!selectedSpace) return null;

    if (showAdminRoutes) {
      return (
        <Switch>
          {hasOrganisationTab ? (
            <Route
              path={`/${BaseAppRoute.organisation}/:organisationName`}
              component={OrganisationAdminRoutes}
            />
          ) : null}
          {hasSpacesTab ? (
            <Route path={`/${BaseAppRoute.spaces}/:spaceName`} component={SpaceAdminRoutes} />
          ) : null}
          <Redirect to={redirectRoute} />;
        </Switch>
      );
    }

    return (
      <Switch>
        {hasOrganisationTab ? (
          <Route
            path={`/${BaseAppRoute.organisation}/:organisationName`}
            component={OrganisationRoutes}
          />
        ) : null}
        {hasSpacesTab ? (
          <Route path={`/${BaseAppRoute.spaces}/:spaceName`} component={SpaceRoutes} />
        ) : null}
        <Redirect to={redirectRoute} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={`/${BaseAppRoute.selectSpace}`} component={SelectSpace} />
      <Redirect to={{ pathname: redirectRoute }} />
    </Switch>
  );
});

export default SpacesRoutes;
