import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import { AppLevelSection, PageNames } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";

import { TeamMember } from "../../types";
import AddTeamMemberModal from "./components/AddTeamMemberModal/AddTeamMemberModal";
import EditTeamMemberModal from "./components/EditTeamMemberModal/EditTeamMemberModal";
import RemoveTeamMemberModal from "./components/RemoveTeamMemberModal/RemoveTeamMemberModal";

import TeamMembersList from "./components/TeamMembersList/TeamMembersList";
import useSpaceSection from "modules/spaces/data/useSpaceSection";

export const AdminMeetTheTeam = observer(() => {
  const { url } = useRouteMatch();
  useMainSectionVisit({ mainSectionId: AppLevelSection.meetTheTeam });

  const { t } = useTranslation();
  const { spaceSection } = useSpaceSection(AppLevelSection.meetTheTeam);
  const [modal, setModal] = useState<React.ReactNode>(null);

  const handleCloseModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const handleClickDeleteTeamMember = useCallback(
    (teamMember: TeamMember) => {
      setModal(<RemoveTeamMemberModal teamMember={teamMember} onCloseModal={handleCloseModal} />);
    },
    [setModal, handleCloseModal],
  );

  const handleClickAddTeamMember = useCallback(() => {
    setModal(<AddTeamMemberModal onCloseModal={handleCloseModal} />);
  }, [setModal, handleCloseModal]);

  const handleClickEditTeamMember = useCallback(
    (teamMember: TeamMember) => {
      setModal(<EditTeamMemberModal teamMember={teamMember} onCloseModal={handleCloseModal} />);
    },
    [setModal, handleCloseModal],
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle
          title={spaceSection.name ?? t("meetTheTeam_title")}
          eolasPageName={PageNames.miniAppFilesList(AppLevelSection.meetTheTeam)}
        />
        <NavButton to={url.replace(`/${AppLevelSection.meetTheTeam}`, "")} />

        <AddButton onClick={handleClickAddTeamMember} data-testid="add-new-team-member-button">
          {t("meetTheTeam_addNewStaffMember")}
        </AddButton>

        <TeamMembersList
          onClickDeleteTeamMember={handleClickDeleteTeamMember}
          onClickEditTeamMember={handleClickEditTeamMember}
        />
      </InnerPageWrapper>

      <Modal open={!!modal} onClose={handleCloseModal} shouldCloseOnOverlayClick={false}>
        {modal}
      </Modal>
    </>
  );
});
