import { useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { PageTitle, InnerPageWrapper, Button, Text, LoadMore, Title } from "UIKit";
import YourSpaces from "./components/YourSpaces/YourSpaces";
import CreateSpaceModal from "./components/CreateSpaceWizard/CreateSpaceWizard";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "Hooks";
import SpaceSearch from "./components/DiscoverSpaces/components/SpaceSearch/SpaceSearch";
import { useSelectSpaceHome } from "./hooks/useSelectSpaceHome";
import { modalStore } from "Stores/ModalStore";
import { Space, AnalyticsEvents, userStore } from "@eolas-medical/core";
import useUserDetails from "Hooks/useUserDetails";
import { webStore } from "Stores/WebStore";
import { trackEvent } from "API/Analytics";

const variants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};
export const SelectSpace = observer(() => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const {
    searchResults,
    renderSpacesList,
    handleSearchSuccessful,
    handleClearResults,
    handleLoadMore,
    isFetchingNextPage,
    hasNextPage,
  } = useSelectSpaceHome();
  const { isSharedAccount } = useUserDetails();
  const { specialty, completedTour } = userStore.userData;
  const { userID } = userStore;

  const hasAccessToken = Boolean(
    userStore.userSession.inviteId || userStore.userSession.accessLinkId,
  );

  const handleClickCreateSpace = useCallback(() => {
    trackEvent(AnalyticsEvents.CREATE_ORG_STARTED);
    modalStore.openModal({
      variant: "component",
      name: "createSpace",
      Component: <CreateSpaceModal />,
    });
  }, []);

  useEffect(() => {
    if (
      userID &&
      completedTour &&
      specialty &&
      !hasAccessToken &&
      !isSharedAccount &&
      webStore.showCreateSpaceModal
    ) {
      handleClickCreateSpace();
    }
  }, [completedTour, handleClickCreateSpace, hasAccessToken, isSharedAccount, specialty, userID]);

  return (
    <>
      <InnerPageWrapper className="mt-10">
        <div className="flex flex-col space-y-2">
          <PageTitle
            data-testid="find-organisation-title"
            title={t("selectSpace_selectSpace")}
            subTitle={t("pageSubTitle")}
          />

          {flags[LDFlagNames.SHOW_CREATE_ORG_BUTTON] && !flags[LDFlagNames.READ_ONLY_ACTIVATED] ? (
            <div className="self-start">
              <CreateNewSpaceButton
                text={t("selectSpace_create_button")}
                onClick={handleClickCreateSpace}
              />
            </div>
          ) : null}
        </div>
        <motion.div
          className="mt-8 w-full"
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          <SpaceSearch
            onSearchSuccessful={handleSearchSuccessful}
            onClearSearch={handleClearResults}
          />
        </motion.div>
        {!searchResults ? (
          <YourSpaces />
        ) : (
          <SearchSpaceResults
            renderSpacesList={renderSpacesList}
            hasNextPage={!!hasNextPage}
            searchResults={searchResults}
            isFetchingNextPage={!!isFetchingNextPage}
            handleLoadMore={handleLoadMore}
          />
        )}
      </InnerPageWrapper>
    </>
  );
});

const CreateNewSpaceButton = ({ text, onClick }: { text: string; onClick: () => void }) => {
  const media = useMediaQuery();
  const isSmallDevice = media === "sm";
  return (
    <Button
      iconLeft={isSmallDevice ? null : "AddIcon"}
      variant="ghost"
      color="blue"
      size="sm"
      className="h-10 rounded-md hover:bg-opacity-50 font-bold"
      onClick={onClick}
    >
      <Text level={1} className="ml-2">
        {text}
      </Text>
    </Button>
  );
};

type SearchSpaceResultsProps = {
  renderSpacesList: () => React.ReactNode;
  hasNextPage: boolean;
  searchResults: Space[];
  isFetchingNextPage: boolean;
  handleLoadMore: () => void;
};

export const SearchSpaceResults = ({
  renderSpacesList,
  hasNextPage,
  searchResults,
  isFetchingNextPage,
  handleLoadMore,
}: SearchSpaceResultsProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-4">
      {searchResults.length ? (
        <div className="sm:justify-start space-x-2 text-grey-700">
          <Title level={7}>{t("general_results")}</Title>
        </div>
      ) : null}
      <div className="flex flex-col relative space-y-4 mt-4">
        {renderSpacesList()}
        {hasNextPage && !searchResults ? (
          <LoadMore
            loadMoreText={t("selectSpace_load_more_spaces")}
            isLoading={isFetchingNextPage || false}
            onLoadMore={handleLoadMore}
          />
        ) : null}
      </div>
    </div>
  );
};
