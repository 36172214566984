import {
  AnalyticsEvents,
  CommunityLevelSection,
  EolasRegions,
  EolasSearchPayload,
  fromAwsJSON,
  hasStringProp,
  NationalResourceEntityType,
  searchClient,
  sectionStore,
} from "@eolas-medical/core";
import { useSupportedRegion } from "Hooks/useSupportedRegion";
import { trackEvent } from "API/Analytics";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import { NationalBody } from "./useNationalBodies";
import { getEolasCalcs } from "./getEolasCalcs";
import { useGetClinicalCalcStatus } from "./useGetClinicalCalcStatus";
import { fuseSearch } from "shared/hooks/useLocalSearch/fuseSearch";

type SearchParams = {
  query: string;
  mode: "searchbyInput" | "searchByNationalBody";
  region: EolasRegions;
  nationalResourceType?: NationalResourceEntityType;
  organisationId?: string;
};

const useSearchNationalResources = ({
  communitySection,
  nationalBodies,
  entityType,
}: {
  communitySection: CommunityLevelSection;
  nationalBodies?: NationalBody[];
  entityType: NationalResourceEntityType;
}) => {
  const { supportedEolasRegion } = useSupportedRegion();
  const results = useGetClinicalCalcStatus();
  return useLocalMobxSearch({
    mode: "callback",
    data: nationalBodies ?? [],
    searchCallback: async (query: string) => {
      const searchParams: SearchParams = {
        query,
        mode: "searchbyInput",
        region: supportedEolasRegion,
        organisationId: sectionStore.organisationID,
      };
      const response = await searchClient.searchNationalResources({
        ...searchParams,
        nationalResourceType: entityType,
      });
      response.map((response) => {
        const parsedMetadata = fromAwsJSON(response.metadata);
        if (hasStringProp(parsedMetadata, "nationalBody") && !response.description) {
          response.description = parsedMetadata.nationalBody;
        }
      });
      const calcSearchResult = fuseSearch(query, getEolasCalcs(results), [
        "name",
        "description",
      ]).results;
      const searchResult = [...response, ...calcSearchResult];
      return searchResult;
    },
    analyticsCallback: (searchQuery: string, noHits?: number) => {
      trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
        searchString: searchQuery,
        hitCount: noHits,
        origin: communitySection,
      });
    },
  });
};

export default useSearchNationalResources;
