import { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { AppLevelSection, EolasFile, PageNames } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { DeleteFileModal, EolasFileList } from "Components";
import { AddButton, Modal, PageTitle, InnerPageWrapper } from "UIKit";

import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { AddPatientLeafletModal, PatientLeafletQRModal } from "../../components";
import { useSelectPatientLeafletOnMount } from "../../hooks/useSelectPatientLeafletOnMount";
import { NavBackButton } from "shared/components/LogicComponents";

export const PatientLeaflets = observer(() => {
  useMainSectionVisit({ mainSectionId: AppLevelSection.patientLeaflets });
  const { isAdmin } = useSpacesContext();
  const { t } = useTranslation();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { spaceSection } = useSpaceSection(AppLevelSection.patientLeaflets);
  const { isShareFileEnabled, handleOnShareFile, shareModal, isShareModalOpen, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const onCloseModal = () => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
    setModal(null);
  };

  const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

  const onAddFile = () => {
    setModal(
      <AddPatientLeafletModal
        mainSectionType={AppLevelSection.patientLeaflets}
        onCloseModal={onCloseModal}
      />,
    );
  };
  const onEditFile = (file: EolasFile) => {
    setModal(
      <AddPatientLeafletModal
        mainSectionType={AppLevelSection.patientLeaflets}
        leaflet={file}
        onCloseModal={onCloseModal}
      />,
    );
  };
  const onDeleteFile = (file: EolasFile) => {
    setModal(<DeleteFileModal file={file} onCloseModal={onCloseModal} />);
  };
  const onShowQR = useCallback((file: EolasFile) => {
    setModal(<PatientLeafletQRModal file={file} />);
  }, []);

  useSelectPatientLeafletOnMount({ onSelectFile: onShowQR });

  return (
    <>
      <InnerPageWrapper>
        <PageTitle
          title={spaceSection.name ?? t(AppLevelSection.patientLeaflets)}
          eolasPageName={PageNames.miniAppFilesList(AppLevelSection.patientLeaflets)}
        />
        <NavBackButton modePreference="removeFromPath" />

        {isAdmin ? (
          <AddButton onClick={onAddFile}>{t("fileWithSearch_addNew_file")}</AddButton>
        ) : null}

        <EolasFileList
          isAdmin={isAdmin}
          onEdit={onEditFile}
          onAddItem={onAddFile}
          onSelectFile={onShowQR}
          onDelete={onDeleteFile}
          mainSectionId={AppLevelSection.patientLeaflets}
          onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      </InnerPageWrapper>

      <Modal open={isModalOpen} onClose={onCloseModal}>
        {modal || shareModal || removeFavouriteModal}
      </Modal>
    </>
  );
});
