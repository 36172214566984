import { Step } from "UIKit";
import SpaceRequestedSuccess from "./SpaceRequestedModal";
import { useTranslation } from "react-i18next";

interface useSpaceRequestedSuccessStepProps {
  spaceRequestError: boolean;
}

export const useSpaceRequestedSuccessStep = ({
  spaceRequestError,
}: useSpaceRequestedSuccessStepProps): Step<"newUKSpaceRequested"> => {
  const { t } = useTranslation();
  return {
    name: "newUKSpaceRequested",
    component: <SpaceRequestedSuccess spaceRequestError={spaceRequestError} />,
    nextLabel: t("general_close"),
    hideButtons: true,
  };
};
