import React, { ForwardedRef, ReactNode } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

export type InputSize = "xs" | "sm" | "md" | "lg" | "xl";

export interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, "size"> {
  className?: string;
  size?: InputSize;
  inputClassName?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  rightSection?: ReactNode;
}

const inputSizes: Record<
  InputSize,
  { containerClass: string; inputClass: string; iconSize: number }
> = {
  xs: { containerClass: "h-6 px-1", inputClass: "text-sm", iconSize: 12 },
  sm: { containerClass: "h-8 px-2", inputClass: "text-base", iconSize: 16 },
  md: { containerClass: "h-10 px-2", inputClass: "text-base", iconSize: 20 },
  lg: { containerClass: "h-10 sm:h-12 px-2", inputClass: "text-base", iconSize: 24 },
  xl: { containerClass: "h-12 sm:h-16 px-2", inputClass: "text-xl", iconSize: 32 },
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    iconLeft,
    iconRight,
    size = "md",
    className,
    inputClassName,
    rightSection,
    style,
    ...props
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { containerClass, iconSize, inputClass } = inputSizes[size];

  return (
    <div
      ref={ref}
      className={tw(`
            transition-all
            flex items-center bg-white ${containerClass} rounded-md
            border border-grey-300 
            ${className}
          `)}
      style={style}
    >
      {iconLeft
        ? React.cloneElement(iconLeft as React.ReactElement, { height: iconSize, width: iconSize })
        : null}

      <input
        className={tw(`${inputClass} h-full mx-2 flex-1 bg-transparent ${inputClassName}`)}
        {...props}
      />

      {iconRight
        ? React.cloneElement(iconRight as React.ReactElement, { height: iconSize, width: iconSize })
        : null}

      {rightSection}
    </div>
  );
});
