import { useCallback, useEffect, useState, useImperativeHandle, RefObject, LegacyRef } from "react";
import { InnerModalWrapper, ModalBody, ModalWizardButtons, ModalHeader } from "../Modal/components";
import { Title, Text, Button } from "UIKit";
import { WizardProgress } from "UIKit/FormElements";
import { Step, WizardRef } from "./types";
import { defaultNextStep, defaultPrevStep, getStepIndexFromSteps } from "./helpers";
import { twMerge } from "tailwind-merge";

interface WizardProps<T extends string> {
  wizardControl: RefObject<WizardRef<T>>;
  steps: Step<T>[];
  initialStep?: T;
  showSteps?: boolean;
  showProgress?: boolean;
  getNextStep?: (currentStep: T) => T;
  getPrevStep?: (currentStep: T) => T;
  getStepClassName?: (currentStep: string) => string;
  modalBodyRef?: LegacyRef<HTMLDivElement>;
  showAltSubmit?: (step: string) => boolean;
  onClickAltSubmit?(): void;
  altSubmitLabel?: string;
}

const Wizard = <T extends string>(props: WizardProps<T>) => {
  const {
    wizardControl,
    steps,
    initialStep,
    showSteps = false,
    showProgress = false,
    getNextStep = (currentStep: T) => defaultNextStep(steps, currentStep),
    getPrevStep = (currentStep: T) => defaultPrevStep(steps, currentStep),
    getStepClassName = () => "",
    modalBodyRef = undefined,
    showAltSubmit,
    altSubmitLabel,
  } = props;

  const [currentStep, setCurrentStep] = useState<T>(initialStep || steps[0].name);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const currentStepIndex = getStepIndexFromSteps(steps, currentStep);

  const {
    title,
    subtitle,
    subtitleClassName = "",
    component,
    canProceed = true,
    submitError,
    isLoading = false,
    onSubmit,
    onAltSubmit,
    onPrev,
    nextLabel = "Next",
    backLabel = "Back",
    backDisabled = false,
    nextDisabled = false,
    onlySubmitStep = false,
    topIcon,
    hideButtons = false,
  } = steps[currentStepIndex];

  const showAltSubmitButton = () => {
    if (showAltSubmit) {
      return showAltSubmit(currentStep);
    }
    return false;
  };

  const handleNext = useCallback(() => {
    const nextStep = getNextStep(currentStep);

    if (nextStep) {
      setCurrentStep(nextStep);
    }
  }, [currentStep, getNextStep]);

  const handlePrevious = async () => {
    if (onPrev) {
      await onPrev();
    }

    const prevStep = getPrevStep(currentStep);

    if (prevStep) {
      setCurrentStep(prevStep);
    }
  };

  const handleSubmit = async () => {
    if (onSubmit) {
      await onSubmit();
      setIsSubmitting(true);
      return;
    }
    handleNext();
  };

  const handleAltSubmit = async () => {
    if (onAltSubmit) {
      await onAltSubmit();
      setIsSubmitting(true);
      return;
    }
    handleNext();
  };

  useEffect(() => {
    if (isSubmitting) {
      if (canProceed) {
        handleNext();
        setIsSubmitting(false);
      }
      if (submitError) {
        setIsSubmitting(false);
      }
    }
  }, [canProceed, handleNext, isSubmitting, submitError]);

  // Expose internal method using ref
  useImperativeHandle<unknown, WizardRef<T>>(wizardControl, () => ({
    submit: handleSubmit,
    steps,
  }));

  const renderButtons = () => {
    if (hideButtons) {
      return null;
    }

    if (onlySubmitStep) {
      return (
        <div className="flex justify-center">
          <Button
            onClick={handleSubmit}
            isLoading={isSubmitting || isLoading}
            type="submit"
            disabled={nextDisabled}
          >
            {nextLabel}
          </Button>
        </div>
      );
    }

    return (
      <ModalWizardButtons
        maxSteps={steps.length}
        currentStep={currentStepIndex}
        backDisabled={backDisabled}
        nextDisabled={nextDisabled}
        onBack={handlePrevious}
        isLoading={isSubmitting || isLoading}
        onNext={handleSubmit}
        backLabel={backLabel}
        nextLabel={nextLabel}
        showSteps={showSteps}
        altSubmit={showAltSubmitButton()}
        altSubmitLabel={altSubmitLabel}
        onClickAltSubmit={handleAltSubmit}
      />
    );
  };

  const showHeader = topIcon || title || subtitle;

  return (
    <InnerModalWrapper className={getStepClassName(currentStep)}>
      {showProgress ? (
        <WizardProgress currentStep={currentStepIndex} maxSteps={steps.length} />
      ) : null}

      {showHeader ? (
        <ModalHeader className="flex flex-col items-start">
          {topIcon ? <div className="w-10 h-10">{topIcon}</div> : null}
          {title ? <Title level={6}>{title}</Title> : null}
          {subtitle ? (
            <Text level={1} className={twMerge(`text-grey-700 ${subtitleClassName}`)}>
              {subtitle}
            </Text>
          ) : null}
        </ModalHeader>
      ) : null}
      <div ref={modalBodyRef} className="flex flex-col h-full">
        <ModalBody className="overflow-y-auto overflow-x-hidden flex-grow max-h-80vh">
          <div className="grid grid-cols-2 gap-x-6 gap-y-2">
            <div className="col-span-2">
              {component}
              {renderButtons()}
            </div>
          </div>
        </ModalBody>
      </div>
    </InnerModalWrapper>
  );
};

export default Wizard;
