import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps, useHistory, useRouteMatch } from "react-router-dom";
import { useCallback, useMemo } from "react";
import {
  MedusaSectionWithItems,
  OrganisationLevelSection,
  PageNames,
  sectionStore,
} from "@eolas-medical/core";
import { InnerPageWrapper, Loader, NavButton, PageTitle, SubSectionTile, Text } from "UIKit";
import { medusaIconsMapper } from "../IconMapper";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { BaseAppRoute } from "Utilities/types";

export const MedusaSubSections: React.FC<RouteComponentProps> = observer(() => {
  const history = useHistory();
  const { params } = useRouteMatch<{ id?: string; sectionName?: string }>();
  const { t } = useTranslation();

  const pageTitle = useMemo(() => params.sectionName || "Medusa Medications", [params.sectionName]);

  const renderItem = useCallback(({ item }: { item: MedusaSectionWithItems }) => {
    const path = `${OrganisationLevelSection.medusaMedications}/${item.id}`;
    return (
      <div className="mb-5">
        <Link key={item.id} to={path}>
          <SubSectionTile title={item.name} image={medusaIconsMapper(item.id)} />
        </Link>
      </div>
    );
  }, []);

  const goToMainSectionsPath = `/${BaseAppRoute.organisation}/${sectionStore.organisationName}`;

  return (
    <InnerPageWrapper>
      <FileBreadcrumbs
        shouldShowSpaceName={true}
        mainSectionId={
          sectionStore
            .getMainSectionList("hospital")
            .find(({ icon }) => icon === "medusaMedications")?.id
        }
      />
      <PageTitle
        titleClass="capitalize"
        title={t("medusa_main_title", { title: pageTitle })}
        eolasPageName={PageNames.MedusaSectionsList}
      />
      <NavButton onClick={() => history.push(goToMainSectionsPath)} />
      {sectionStore.medusaSectionsWithItems.length ? (
        <EolasVersatileList items={sectionStore.medusaSectionsWithItems} renderItem={renderItem} />
      ) : (
        /** NOTE: this is a temp loader for users transitioning from old medusa to new as we load
         * the new medusa sections for the first time:
         */
        <div className="w-full flex flex-col justify-center items-center">
          <Loader className="bg-transparent" />
          <Text className="text-center mt-8" level={2}>
            {t("medusa_loading")}
          </Text>
        </div>
      )}
    </InnerPageWrapper>
  );
});
