import { useTranslation, Trans } from "react-i18next";
import spaceBgLines from "Assets/Img/space-background-lines.svg";
import { Title, Text } from "UIKit";
import { EolasTitleIcon, NewScreenIcon } from "Assets";

interface useConfirmSpaceTrialProps {
  spaceName: string;
  description?: string;
}

const ConfirmSpaceTrial = ({ spaceName, description }: useConfirmSpaceTrialProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-full overflow-y-auto bg-white text-sm ">
      <Text level={1}>
        <Trans
          i18nKey="confirmSpaceModal_final_step_description"
          components={{
            1: <span className="font-semibold" />,
            2: <span className="font-semibold" />,
          }}
        />
      </Text>

      <div
        className="my-12 p-4 xl:px-8 rounded-lg h-80 w-full bg-cover  bg-no-repeat bg-blue-spaces-background flex flex-col xl:flex-row xl:justify-between"
        style={{ backgroundImage: `url(${spaceBgLines})`, backgroundPositionY: "-100px" }}
      >
        <div className="flex flex-col w-full xl:w-1/2">
          <EolasTitleIcon className="w-16" />
          <Text level={2} className="mt-4 text-gray-600">
            {t("confirmSpaceModal_eolas_text")}
          </Text>
          <Title level={6} className="text-blue-500 line-clamp-1 mt-2 mb-3">
            {spaceName}
          </Title>
          <Text level={2} className="line-clamp-3 mt-2">
            {description}
          </Text>
        </div>
        <NewScreenIcon className="w-1/3 hidden xl:block" />
      </div>

      <div className="my-6">
        <Title level={7} className="text-lg font-semibold mb-4">
          {t("confirmSpaceModal_subscription_header")}
        </Title>
        <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
          <li>
            <Text level={1}>
              <Trans
                i18nKey="confirmSpaceModal_subscription_free_trial"
                components={{ 1: <span className="font-semibold" /> }}
              />
            </Text>
          </li>
          <li>
            <Text level={1}>
              <Trans
                i18nKey="confirmSpaceModal_subscription_full_access"
                components={{ 1: <span className="font-semibold" /> }}
              />
            </Text>
          </li>

          <li>
            <Text level={1}>
              <Trans
                i18nKey="confirmSpaceModal_subscription_cancel_anytime"
                components={{ 1: <span className="font-semibold" /> }}
              />
            </Text>
          </li>
        </ul>
        <Text level={1} className="mt-4 text-gray-600">
          {t("confirmSpaceModal_subscription_footer")}
        </Text>
      </div>
    </div>
  );
};

export default ConfirmSpaceTrial;
