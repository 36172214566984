import React from "react";
import { Title } from "UIKit";
import { getVerificationStatusClass } from "Pages/MeTab/helpers";
import { ProfileVerification, ProfileVerificationStatus } from "@eolas-medical/core";
import { Pill } from "UIKit/Pill";
import { ProfileStatusLabel } from "Pages/MeTab/types";
import { Sizes } from "UIKit/types";

interface VerificationValueProps {
  profileVerification?: ProfileVerification;
}

const VerificationValue = ({ profileVerification }: VerificationValueProps) => {
  const { icon, className } = getVerificationStatusClass(profileVerification?.status);
  return (
    <div className="flex flex-col">
      {profileVerification ? (
        <div>
          <Title level={9} className="text-grey-700 inline-flex mb-2">
            {profileVerification.professionalBody}
          </Title>
          {": "}
          <Title level={9} className="inline-flex">
            {profileVerification.registrationNumber}
          </Title>
        </div>
      ) : null}
      <div>
        <Pill
          value={
            ProfileStatusLabel[profileVerification?.status || ProfileVerificationStatus.UNVERIFIED]
          }
          icon={icon}
          className={className}
          size={Sizes.SMALL}
        />
      </div>
    </div>
  );
};

export default VerificationValue;
