import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import { newSpaceImage } from "Assets";
import { useTranslation, Trans } from "react-i18next";
import { Text } from "UIKit";

const NewSpaceInfo = () => {
  const { t } = useTranslation();
  trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_STARTED);

  return (
    <div className="w-full overflow-y-auto bg-white text-sm">
      <img src={newSpaceImage} alt="" />
      <div className="mt-12 flex flex-col">
        <Text level={1}>
          <Trans
            i18nKey="newSpaceModal_eolas_medical_title"
            components={{
              1: <span className="font-semibold" />,
              2: <span className="font-semibold" />,
            }}
          />
        </Text>
        <Text level={1} className="my-12">
          <Trans
            i18nKey="newSpaceModal_eolas_medical_description"
            components={{ 1: <span className="font-semibold" /> }}
          />
        </Text>
      </div>

      <div className="flex flex-col space-y-6">
        <Text level={1} className="font-semibold">
          {t("newSpaceModal_add_details_list_title")}
        </Text>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <Text level={1}>
              <Trans
                i18nKey="newSpaceModal_manage_guidelines_protocols"
                components={{ 1: <span className="font-semibold" /> }}
              />
            </Text>
          </li>
          <li>
            <Text level={1}>
              <Trans
                i18nKey="newSpaceModal_manage_education_training"
                components={{ 1: <span className="font-semibold" /> }}
              />
            </Text>
          </li>
          <li>
            <Text level={1}>
              <Trans
                i18nKey="newSpaceModal_manage_checklists_forms"
                components={{ 1: <span className="font-semibold" /> }}
              />
            </Text>
          </li>
          <li>
            <Text level={1}>
              <Trans
                i18nKey="newSpaceModal_manage_updates_announcements"
                components={{ 1: <span className="font-semibold" /> }}
              />
            </Text>
          </li>
          <li>
            <Text level={1}>
              <Trans
                i18nKey="newSpaceModal_manage_operational_documents"
                components={{ 1: <span className="font-semibold" /> }}
              />
            </Text>
          </li>
          <li className="font-semibold">
            <Text level={1}>{t("newSpaceModal_manage_more")} </Text>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NewSpaceInfo;
