import {
  CommunityMedicationsMiniApp,
  contentClient,
  EolasFile,
  isFlashCardContentItem,
  Maybe,
  sectionStore,
} from "@eolas-medical/core";
import { mapToContentItem } from "../helpers";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { useQuery } from "@tanstack/react-query";

interface UseContentItem {
  mainSectionId: string;
  flashcardId: string;
  enabled?: boolean;
  isMedicationHighlight?: boolean;
}
const useFlashcardItem = ({
  flashcardId,
  enabled = true,
  isMedicationHighlight,
}: UseContentItem) => {
  let file: Maybe<EolasFile> = null;
  let isFetching = false;
  let isError = false;
  let error: unknown = null;

  const {
    file: contentFile,
    isFetching: contentIsFetching,
    isError: contentIsError,
    error: contentError,
  } = useGetFile({
    id: flashcardId,
    options: { isEnabled: enabled && !isMedicationHighlight },
  });

  /**
   * Slightly hacky way to get the flashcard content item for medication highlights, as they are stored in the content repo
   * service, but they are not part of the Space / Org, therefore not available in the local content item DB.
   * Longer term solution is to separate out all medication highlights code
   **/

  const {
    data: highlightFile,
    isFetching: highlightIsFetching,
    isError: highlightIsError,
    error: highlightError,
  } = useQuery({
    queryKey: ["medicationHighlight", flashcardId, isMedicationHighlight],
    queryFn: async () => {
      const mainSectionId = sectionStore.communitySectionsOrder.find(
        ({ icon }) => icon === CommunityMedicationsMiniApp.pharmaFlashcards,
      )?.id;
      if (!mainSectionId) {
        return null;
      }
      const eolasFile = await contentClient.getContentItemByMainSectionId({
        mainSectionId,
        contentId: flashcardId,
      });
      return eolasFile;
    },
    enabled: enabled && isMedicationHighlight,
  });

  if (isMedicationHighlight) {
    file = highlightFile;
    isFetching = highlightIsFetching;
    isError = highlightIsError;
    error = highlightError;
  } else {
    file = contentFile;
    isFetching = contentIsFetching;
    isError = contentIsError;
    error = contentError;
  }

  const flashcardData = file ? mapToContentItem(file) : undefined;
  const flashcard =
    flashcardData && isFlashCardContentItem(flashcardData) ? flashcardData : undefined;

  return {
    flashcard,
    flashcardLoading: isMedicationHighlight ? isFetching : false,
    isError,
    error,
  };
};

export default useFlashcardItem;
