import { useMemo } from "react";

import {
  ContentItem,
  DsmData,
  getObjectEntries,
  hasStringProp,
  PageNames,
  parseDsmMetadata,
} from "@eolas-medical/core";
import { useDsmViewer } from "./hooks/useDsmViewer";
import { InnerPageWrapper, Loader, PageTitle } from "UIKit";
import { NavBackButton } from "shared/components/LogicComponents";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import useFetchContentItem from "modules/contentRepository/data/useContentItem";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { DsmRenderItem, ItemForRender } from "./types";
import { DsmQuestion } from "./component/DsmQuestion";
import { isDsmFooterItem, isDsmQuestionItem } from "./typeguards";
import { DsmFooter } from "./component/DsmFooter";
import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { ContentItemParams } from "shared/pages/ContentRepository/context/types";
import { useGetDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useGetDraftFile";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { useConstructFileBreadcrumbProps } from "Pages/Spaces/hooks/useConstructFileBreadcrumbProps";

export const StandaloneDsmViewer = () => {
  const { mainSectionId, contentItemId } = useParamsDecoded<{
    mainSectionId: string;
    contentItemId: string;
    versionNo?: string;
  }>();

  const { fileIdOfDraftFile, shadowCopyContentId, shouldShowBreadcrumbs } = useGetSearchParams({
    paramList: getObjectEntries(ContentItemParams).map(([, v]) => v),
  });

  return (
    <NestedDsmViewer
      mainSectionId={mainSectionId}
      contentItemId={contentItemId}
      shadowCopyContentId={shadowCopyContentId}
      fileIdOfDraftFile={fileIdOfDraftFile}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs === "true"}
    />
  );
};

export const NestedDsmViewer = ({
  mainSectionId,
  contentItemId,
  backBehaviour,
  shadowCopyContentId,
  fileIdOfDraftFile,
  shouldShowBreadcrumbs,
}: {
  mainSectionId: string;
  contentItemId: string;
  backBehaviour?: "goBack" | "goHome";
  shadowCopyContentId: string | null;
  fileIdOfDraftFile: string | null;
  shouldShowBreadcrumbs?: boolean;
}) => {
  const { contentItem, isContentItemLoading } = useFetchContentItem({
    mainSectionId,
    contentId: fileIdOfDraftFile || contentItemId,
  });

  const { data: draftFileData, isFetching: isDraftFileFetching } =
    useGetDraftFile(fileIdOfDraftFile);

  let item: ContentItem | undefined = contentItem;

  if (fileIdOfDraftFile && draftFileData?.draftFile) {
    item = mapToContentItem(draftFileData.draftFile);
  }

  const data = useMemo(() => {
    if (!item) {
      return null;
    }
    const dsm = parseDsmMetadata(item.metadata);

    if (!dsm) {
      return null;
    }

    return { name: item.name, dsmData: dsm.dsmData };
  }, [item]);

  if (isContentItemLoading || isDraftFileFetching || !data) {
    return (
      <InnerPageWrapper>
        <NavBackButton modePreference="goBackHistory" />
        <Loader className="h-80vh" />
      </InnerPageWrapper>
    );
  }

  return (
    <DsmGenericViewer
      contentItem={contentItem}
      dsmData={data.dsmData}
      name={data.name}
      backBehaviour={backBehaviour}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
    />
  );
};

const keyExtractor = (item: ItemForRender) =>
  hasStringProp(item, "type") ? item.type : item.qNumber;

const renderItem = (item: DsmRenderItem) => {
  if (isDsmFooterItem(item)) {
    return <DsmFooter item={item} />;
  }
  if (isDsmQuestionItem(item)) {
    return <DsmQuestion item={item} />;
  }
  return <></>;
};

const DsmGenericViewer = ({
  dsmData,
  name,
  backBehaviour = "goBack",
  contentItem,
  shadowCopyContentId,
  shouldShowBreadcrumbs,
}: {
  dsmData: DsmData;
  name: string;
  contentItem?: ContentItem;
  backBehaviour?: "goBack" | "goHome";
  shadowCopyContentId: string | null;
  shouldShowBreadcrumbs?: boolean;
}) => {
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });
  const { questionsArray, context } = useDsmViewer({ dsmData });

  const { mainSectionId, parentId } = useConstructFileBreadcrumbProps({
    id: shadowCopyContentId ? shadowCopyContentId : contentItem?.id ?? "",
  });
  return (
    <InnerPageWrapper>
      {shouldShowBreadcrumbs ? (
        <FileBreadcrumbs
          shouldShowSpaceName={true}
          mainSectionId={mainSectionId}
          parentId={parentId}
          fileName={name}
        />
      ) : null}
      <PageTitle title={name} eolasPageName={PageNames.DsmViewer} />
      <NavBackButton modePreference="removeFromPath" overrideGoBackCallback={handleGoBack} />
      <EolasVersatileList
        context={context}
        items={questionsArray}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    </InnerPageWrapper>
  );
};
