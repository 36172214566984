import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import envConfig from "env-config";
import { Icon } from "UIKit/IconPicker/types";
import { eolasLogger } from "@eolas-medical/core";

const useRemoteIcons = () => {
  const fetchJson = () =>
    axios.get(
      `https://${envConfig.REACT_APP_STATIC_BASE_URL}/SectionImages/svgIcons/iconsManifest.json`,
    );

  const { data, isLoading } = useQuery({
    queryKey: ["remoteIcons"],
    queryFn: fetchJson,
    staleTime: 30000,
    onError: (error) => {
      eolasLogger.error(new Error("Error fetching remote icons"), { error });
    },
  });

  const remoteIcons: Icon[] = useMemo(
    () =>
      data?.data.map((value: { name: string; file: string }) => ({
        name: value.name,
        fileName: value.file,
        url: `https://${envConfig.REACT_APP_STATIC_BASE_URL}/SectionImages/svgIcons/${value.file}`,
      })),
    [data],
  );

  return {
    remoteIcons: remoteIcons ?? [],
    remoteIconsLoading: isLoading,
  };
};

export default useRemoteIcons;
