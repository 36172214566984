import {
  AppLevelSection,
  EolasFile,
  isEolasShadowCopyFile,
  makeFileDetailedBreadcrumbs,
  sectionStore,
} from "@eolas-medical/core";
import { EolasContentMapFile, EolasFileSummary } from "./types";
import { SpaceUser } from "clients/departments/types";
import { isValid, parseISO, format } from "date-fns";

export const makeIterationCallback = (summaries: EolasFileSummary[]) => {
  return (value: EolasFile) => {
    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(value.mainSectionID);

    if (mainSectionType === AppLevelSection.checklists) {
      return;
    }

    summaries.push(mapToEolasSummaryFile(value));
  };
};

export const makeContentMapIterationCallback = ({
  summaries,
  spaceUsers,
}: {
  summaries: EolasContentMapFile[];
  spaceUsers: SpaceUser[];
  spaceId: string;
}) => {
  return (value: EolasFile) => {
    const mainSectionType = sectionStore.getMainSectionTypeFromMainSectionID(value.mainSectionID);

    if (mainSectionType === AppLevelSection.checklists) {
      return;
    }

    summaries.push(mapToEolasEnrichedFile(value, spaceUsers));
  };
};

export const mapToEolasSummaryFile = (eolasFile: EolasFile): EolasFileSummary => {
  return {
    id: eolasFile.id,
    name: eolasFile.name,
    isShadowCopy: isEolasShadowCopyFile(eolasFile),
    isUnpublished: eolasFile.isDraft ?? false,
    mainSectionId: eolasFile.mainSectionID,
    ownerId: eolasFile.ownerID,
  };
};

export const mapToEolasEnrichedFile = (
  eolasFile: EolasFile,
  spaceUsers: SpaceUser[],
): EolasContentMapFile => {
  const mainSectionName =
    sectionStore.getMainSectionChildReference(eolasFile.mainSectionID ?? "")?.name ?? "";

  // Initialise pathText to handle potential errors in breadcrumb generation
  let pathText = "";

  try {
    const { fullPath } = makeFileDetailedBreadcrumbs({
      mainSectionId: eolasFile.mainSectionID,
      mainSectionName,
      parentId: eolasFile.parentID,
      fileName: eolasFile.name,
    });

    pathText = fullPath
      .map((breadcrumb) => {
        const [name] = Object.entries(breadcrumb)[0];
        return name;
      })
      .join("/");
  } catch (error) {
    console.error("Error generating breadcrumbs for file:", eolasFile.name, error);
    // Leave pathText as an empty string or provide a fallback value
  }

  // Function to find user by ID
  const findUserById = (userId: string | undefined): string => {
    if (!userId) return "No Data";
    const user = spaceUsers.find((user) => user.userID === userId);
    if (user && user.user) {
      // eslint-disable-next-line camelcase
      const { given_name, family_name, email } = user.user;
      // eslint-disable-next-line camelcase
      return `${given_name ?? "No First Name"} ${family_name ?? "No Last Name"} (${
        email ?? "No Email"
      })`;
    }
    return "No Data";
  };

  const formattedExpiryDate =
    eolasFile.expiryDate && isValid(parseISO(eolasFile.expiryDate))
      ? format(parseISO(eolasFile.expiryDate), "dd MMMM yyyy")
      : eolasFile.expiryDate || "NO EXPIRY DATE SET";

  return {
    name: eolasFile.name,
    createdAt: format(new Date(eolasFile.createdAt), "dd MMMM yyyy"),
    updatedAt: format(new Date(eolasFile.updatedAt), "dd MMMM yyyy"),
    isShadowCopy: isEolasShadowCopyFile(eolasFile),
    isUnpublished: eolasFile.isDraft ?? false,
    path: pathText,
    createdBy: findUserById(eolasFile.createdBy ?? ""), // Map createdBy to user info
    // @ts-expect-error updateBy is saved as a string of user id yet this type expects an object. Why is that?
    updatedBy: findUserById(eolasFile.lastUpdatedBy), // Map updatedBy to user info
    expiryDate: formattedExpiryDate,
  };
};
