import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ClientMainSectionTile, InnerPageWrapper } from "UIKit";
import GridContainer from "UIKit/GridContainer/GridContainer";
import { useIcons } from "Hooks";
import { getSpaceRoute } from "Pages/Spaces/helpers";
import useSpaceAdminSections from "modules/spaces/data/useSpaceAdminSections";
import { AppLevelSection, OrganisationLevelSection, PageNames, Space } from "@eolas-medical/core";
import { useSpaceDetailsModal } from "./hooks/useSpaceDetailsModal";
import AccessQRCode from "./components/AccessQRCode/AccessQRCode";
import EditSpaceDetailsWizard from "./components/EditSpaceDetailsWizard/EditSpaceDetailsWizard";
import { modalStore } from "Stores/ModalStore";
import { AdminTitleDetails } from "./components/AdminTitleDetails/AdminTitleDetails";
import { usePageAnalytics } from "Hooks/usePageAnalytics";
import { useEffect } from "react";
import PostSpaceCreationModal from "../SelectSpace/components/DiscoverSpaces/components/PostSpaceCreationModal/PostSpaceCreationModal";
import { webStore } from "Stores/WebStore";

const SpaceAdmin = observer(() => {
  const { t } = useTranslation();
  const { getSpaceAdminDashboardIcon } = useIcons();
  const { activeAdminMainSections, handleAnalyticsClick } = useSpaceAdminSections();

  usePageAnalytics({ eolasPageName: PageNames.homePage("space") + "Admin" });

  const {
    updatingSpace,
    handleSpaceUpdated,
    selectedSpace,
    updateSpaceSuccess,
    handleClosePostSpaceCreationModal,
  } = useSpaceDetailsModal();

  const handleClickShareSpace = () =>
    modalStore.openModal({
      variant: "component",
      Component: <AccessQRCode onClose={() => modalStore.closeModal()} />,
      name: "accessQRCode",
    });

  const handleClickEditSpace = (selectedSpace: Space) =>
    modalStore.openModal({
      variant: "component",
      Component: (
        <EditSpaceDetailsWizard
          onUpdate={handleSpaceUpdated}
          isLoading={updatingSpace}
          selectedSpace={selectedSpace}
          isSuccess={updateSpaceSuccess}
        />
      ),
      name: "editSpace",
    });

  const showPostSpaceCreationModalValue = webStore.showPostSpaceCreationModal;

  // show new space modal when space just created

  useEffect(() => {
    if (showPostSpaceCreationModalValue) {
      modalStore.openModal({
        variant: "component",
        shouldRenderCloseButton: false,
        isDismissible: false,

        Component: <PostSpaceCreationModal onClose={handleClosePostSpaceCreationModal} />,
        name: "postSpaceCreation",
      });
    }
  }, [handleClosePostSpaceCreationModal, showPostSpaceCreationModalValue]);

  if (!selectedSpace) {
    return null;
  }

  return (
    <InnerPageWrapper>
      <AdminTitleDetails
        entity="Space"
        onClickEditSpace={() => handleClickEditSpace(selectedSpace)}
        onClickShareSpace={handleClickShareSpace}
      />
      <GridContainer>
        {activeAdminMainSections.map(({ id, route, title, description, disabled }) => {
          const Icon = getSpaceAdminDashboardIcon(id);

          const component = (
            <ClientMainSectionTile
              id={id as AppLevelSection | OrganisationLevelSection}
              title={t(title)}
              description={t(description)}
              Icon={<Icon className="h-8 w-8 lg:h-14 lg:w-14" />}
              disabled={disabled}
            />
          );

          if (!disabled) {
            if (id === "adminAnalytics" && handleAnalyticsClick) {
              return (
                <div className="cursor-pointer" key={id} onClick={handleAnalyticsClick}>
                  <ClientMainSectionTile
                    shouldShowBetaTag
                    id={id as AppLevelSection | OrganisationLevelSection}
                    title={t(title)}
                    description={t(description)}
                    Icon={<Icon className="h-8 w-8 lg:h-14 lg:w-14" />}
                  />
                </div>
              );
            }
            return (
              <Link key={id} to={`${getSpaceRoute(selectedSpace)}/${route}`} data-testid={title}>
                {component}
              </Link>
            );
          }

          return (
            <div key={id}>
              <ClientMainSectionTile
                id={id as AppLevelSection | OrganisationLevelSection}
                title={t(title)}
                description={t(description)}
                Icon={<Icon className="h-8 w-8 lg:h-14 lg:w-14" />}
                disabled={disabled}
              />
            </div>
          );
        })}
      </GridContainer>
    </InnerPageWrapper>
  );
});

export default SpaceAdmin;
