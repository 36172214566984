import { snakeCase } from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  AnalyticsEvents,
  CommunityLevelSection,
  EolasFile,
  NationalResourceEntityType,
  NationalResourcesClickPayload,
  PageNames,
} from "@eolas-medical/core";

import { removeLastUrlPath } from "Utilities";
import { useRecordMainSectionVisit, useSelectFile } from "Hooks";
import { InnerPageWrapper, Loader, Modal, NavButton, PageTitle } from "UIKit";

import { RouteComponentProps, useHistory } from "react-router-dom";
import useNationalBodies, { NationalBody } from "modules/nationalResources/data/useNationalBodies";
import { CommunitySectionType, EOLAS_CALCULATOR } from "./types";
import useSearchNationalResources from "modules/nationalResources/data/useSearchNationalResources";
import { trackEvent } from "API/Analytics";
import { BaseAppRoute, SubSectionRouteParams } from "Utilities/types";
import { isEolasFile } from "modules/contentRepository/typeguards";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { EolasSectionTile } from "UIKit/EolasSectionTile/EolasSectionTile";
import KnowledgeSectionItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { useCallback } from "react";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useShareFile } from "shared/hooks/useShareFile";

const ClinicalCalculators = observer(
  ({ match: { url } }: RouteComponentProps<SubSectionRouteParams>) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { onSelectFile } = useSelectFile();
    useRecordMainSectionVisit({
      mainSectionType: CommunityLevelSection.clinicalCalculators,
    });

    const { nationalBodies, gettingNationalBodies } = useNationalBodies({
      entityType: NationalResourceEntityType.CALCULATOR,
    });

    const {
      handleSearchClick,
      searchInstanceId,
      handleClearSearch,
      searchResults,
      isSearchActive,
      isSearchLoading,
    } = useSearchNationalResources({
      communitySection: CommunityLevelSection.clinicalCalculators,
      nationalBodies,
      entityType: NationalResourceEntityType.CALCULATOR,
    });

    const handleClickCalculator = useCallback(
      (calculator: EolasFile) => {
        trackEvent<NationalResourcesClickPayload>(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
          nationalResourceType: NationalResourceEntityType.CALCULATOR,
          resourceName: calculator.name,
        });

        if (calculator.description === EOLAS_CALCULATOR) {
          const calculatorName = snakeCase(calculator.name);
          history.push({
            pathname: `clinicalCalculators/eolas_calculator/${calculatorName}/${calculator.id}`,
          });
        } else {
          onSelectFile(calculator);
        }
      },
      [history, onSelectFile],
    );

    const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
      useShareFile();

    const {
      isFavouriteModalOpen,
      closeFavouriteModal,
      removeFavouriteModal,
      showRemoveFavouriteModal,
    } = useRemoveFavouriteModal();

    const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
    const onCloseModal = useCallback(() => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
    }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);

    const renderItem = useCallback(
      ({ item }: { item: EolasFile | NationalBody }) => (
        <div className="mb-3">
          {isEolasFile(item) ? (
            <KnowledgeSectionItem
              eolasFile={item}
              onSelectFile={handleClickCalculator}
              sectionType={CommunitySectionType.NATIONAL_RESOURCE}
              onShareFile={isShareFileEnabled ? () => handleOnShareFile(item) : undefined}
              showRemoveFavouriteModal={showRemoveFavouriteModal}
            />
          ) : (
            <EolasSectionTile
              variant="nationalBody"
              title={item.name}
              onClick={() => {
                const snakeCaseBody = snakeCase(item.name);
                history.push({
                  pathname: `/${BaseAppRoute.knowledge}/clinicalCalculators/${snakeCaseBody}`,
                  state: { body: item.name },
                });
              }}
            />
          )}
        </div>
      ),
      [
        handleClickCalculator,
        isShareFileEnabled,
        handleOnShareFile,
        showRemoveFavouriteModal,
        history,
      ],
    );

    return (
      <>
        <InnerPageWrapper>
          <Modal open={isModalOpen} onClose={onCloseModal}>
            {shareModal || removeFavouriteModal}
          </Modal>
          <PageTitle
            title={t("clinicalCalculators_title")}
            eolasPageName={PageNames.NationalResourcesSections}
          />
          <NavButton to={removeLastUrlPath(url)} />
          {gettingNationalBodies ? (
            <Loader className="p-16" />
          ) : (
            <EolasVersatileList
              key={isSearchActive ? "search-list" : "default-list"}
              className="space-y-6"
              isSearchLoading={isSearchLoading}
              items={isSearchActive ? searchResults : nationalBodies}
              renderItem={renderItem}
              isSearchable
              droppableId="content-section-list"
              searchMode="click"
              onClickSearch={handleSearchClick}
              onClearSearch={handleClearSearch}
              searchInstanceId={searchInstanceId}
            />
          )}
        </InnerPageWrapper>
      </>
    );
  },
);

export default ClinicalCalculators;
