import { observer } from "mobx-react-lite";
import ContentRepositoryContext, {
  defaultKnowledgeContentRepositoryValues,
} from "shared/pages/ContentRepository/context/contentRepository.context";

import useDeleteContentItem from "modules/contentRepository/data/useDeleteContentItem";
import useMedicationsHighlightsSection from "modules/medications/data/useMedicationsHighlightsSection";
import useMedicationHighlightsItems from "modules/medications/data/useMedicationHighlightsItems";
import useReorderContent from "modules/contentRepository/data/useReorderContent";
import useAddFlashcardItem from "modules/contentRepository/data/useAddFlashcardItem";
import useEditFlashcardItem from "modules/contentRepository/data/useEditFlashcardItem";
import useRefreshMedicationsHighlights from "modules/medications/data/useRefreshMedicationHighlights";
import { BaseAppRoute } from "Utilities/types";

interface MedicationsHighlightsProviderProviderProps {
  children?: React.ReactNode;
}

const MedicationsHighlightsProvider: React.FC<MedicationsHighlightsProviderProviderProps> =
  observer(({ children }: MedicationsHighlightsProviderProviderProps) => {
    const medicationsHighlightsSection = useMedicationsHighlightsSection();
    const { onRefreshMedicationsHighlights } = useRefreshMedicationsHighlights();

    const {
      medicationsHighlightsItems: contentItems,
      medicationsHighlightsItemsLoading: contentItemsLoading,
    } = useMedicationHighlightsItems({
      medicationsHighlightsSectionId: medicationsHighlightsSection?.id || "",
    });

    const useAddFlashcardItemHook = useAddFlashcardItem({
      mainSectionId: medicationsHighlightsSection?.id || "",
      parentId: medicationsHighlightsSection?.id || "",
      isSponsored: true,
      onSuccess: onRefreshMedicationsHighlights,
    });

    const useEditFlashcardItemHook = useEditFlashcardItem({
      mainSectionId: medicationsHighlightsSection?.id || "",
      onSuccess: onRefreshMedicationsHighlights,
    });
    const useDeleteContentItemHook = useDeleteContentItem({
      mainSectionId: medicationsHighlightsSection?.id || "",
      onSuccess: onRefreshMedicationsHighlights,
    });
    const useReorderContentHook = useReorderContent(medicationsHighlightsSection?.id || "");

    return (
      <ContentRepositoryContext.Provider
        value={{
          ...defaultKnowledgeContentRepositoryValues,
          contextDomain: "knowledge",
          // @ts-expect-error this is to fix legacy code. Solution is to separate out Med Highlights from here:
          knowledgeContentDomain: "medicationHighlights",
          useContentItemsHook: { contentItems, contentItemsLoading },
          useContentSectionHook: {
            contentSection: medicationsHighlightsSection,
            contentSectionLoading: false,
          },
          useAddFlashcardItemHook,
          useEditFlashcardItemHook,
          useDeleteContentItemHook,
          useReorderContentHook,
          contentItemConfig: {
            shouldAllowFavourites: false,
            shouldAllowSharing: true,
            shouldShowBreadcrumbs: false,
          },
          getFlashcardViewerUrl: ({ flashcard }) => {
            return `/${BaseAppRoute.viewer}/${encodeURIComponent(
              flashcard.mainSectionId,
            )}/flash-cards/${encodeURIComponent(flashcard.id)}?isMedicationHighlight=true`;
          },
        }}
      >
        {children}
      </ContentRepositoryContext.Provider>
    );
  });

export default MedicationsHighlightsProvider;
