import { twMerge } from "tailwind-merge";
import { Text, TextProps } from "./Text";

export interface IconTextProps extends TextProps {
  icon: React.ReactNode;
  className?: string;
}

export const IconText = ({ icon, className, ...otherProps }: IconTextProps) => {
  return (
    <div className={twMerge("flex items-center space-x-2", className)}>
      <div>{icon}</div>
      <Text {...otherProps} />
    </div>
  );
};
