import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { Title } from "UIKit";

const SpaceRequestedSuccess = ({ spaceRequestError }: { spaceRequestError: boolean }) => {
  const { t } = useTranslation();
  trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_STARTED);

  return (
    <div className="w-full overflow-y-auto bg-white text-sm">
      <div className="my-8 text-center">
        <Title level={7}>
          {t(
            spaceRequestError
              ? "selectSpace_create_entity_error"
              : "selectSpace_create_entity_success",
          )}
        </Title>
      </div>
    </div>
  );
};

export default SpaceRequestedSuccess;
