import { useTranslation } from "react-i18next";

import { ChildReference } from "Utilities/types";
import { CheckIcon, ArrowLeftIcon } from "Assets";

import {
  Input,
  Title,
  ModalBody,
  FormError,
  IconButton,
  IconPicker,
  FormElement,
  SuccessModal,
  ModalHeader,
  InnerModalWrapper,
  ModalWizardButtons,
} from "UIKit";

import { useAddSection } from "./hooks";
import useRemoteIcons from "Hooks/useRemoteIcons";

export interface AddSectionModalProps {
  onCloseModal(): void;
  parentID: string;
  addDescription?: boolean;
  sectionReference?: ChildReference;
}

export const AddSectionModal: React.FC<AddSectionModalProps> = ({
  parentID,
  onCloseModal,
  addDescription,
  sectionReference,
}: AddSectionModalProps) => {
  const { t } = useTranslation();

  const { remoteIcons } = useRemoteIcons();

  const {
    name,
    error,
    control,
    iconURL,
    onSubmit,
    isLoading,
    selectIcon,
    isAddingIcon,
    isSuccessful,
    setIsAddingIcon,
  } = useAddSection(parentID, sectionReference);

  if (isSuccessful) {
    return (
      <SuccessModal
        onComplete={onCloseModal}
        text={
          sectionReference
            ? "contentManagement_editFeature_updated"
            : "contentManagement_editFeature_added"
        }
      />
    );
  }

  if (isAddingIcon) {
    return (
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{t("iconPicker_select_icon")}</Title>
          <IconButton
            className="mt-4"
            icon={<ArrowLeftIcon />}
            onClick={() => setIsAddingIcon(false)}
          />
        </ModalHeader>
        <ModalBody>
          <IconPicker
            iconsList={remoteIcons}
            defaultShowIcons
            iconURL={iconURL}
            iconContainerClassname={`
              h-70vh sm:h-60vh
            `}
            iconListClassname={"pr-6 sm:pr-8 lg:pr-10 xl:pr-12"}
            onClick={(icon) => {
              selectIcon(icon);
              setIsAddingIcon(false);
            }}
          />
        </ModalBody>
      </InnerModalWrapper>
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={5}>
          {t(sectionReference ? "editSection_modal_title" : "addSection_modal_title")}
        </Title>
      </ModalHeader>

      <ModalBody>
        <form className="flex flex-col" onSubmit={onSubmit}>
          <FormElement
            required
            id="name"
            control={control}
            label={t("contacts_section_name_label")}
          >
            {(fieldProps, { isValid }) => (
              <Input
                {...fieldProps}
                size="lg"
                disabled={isLoading}
                data-testid="subSectionNameInput"
                placeholder={t("contacts_section_name_placeholder")}
                iconRight={isValid ? <CheckIcon className="text-green" /> : undefined}
              />
            )}
          </FormElement>

          {addDescription ? (
            <FormElement
              required
              id="description"
              control={control}
              label={t("fileWithSearch_addNew_section_description")}
            >
              {(fieldProps) => (
                <Input
                  data-testid="subSectionDescriptionInput"
                  placeholder={t("contacts_section_name_placeholder")}
                  {...fieldProps}
                />
              )}
            </FormElement>
          ) : null}
          <FormElement id="icon" control={control} labelClassName="font-semibold">
            {() => (
              <div>
                <IconPicker
                  iconURL={iconURL || ""}
                  onClick={() => {
                    return;
                  }}
                  iconContainerClassname="h-50vh"
                  onOpen={() => setIsAddingIcon(true)}
                  iconListClassname="pr-4 sm:pr-12 pb-4"
                  label={t("fileWithSearch_addNew_section_icon")}
                />
              </div>
            )}
          </FormElement>

          <ModalWizardButtons
            nextDisabled={!name}
            onBack={onCloseModal}
            isLoading={isLoading}
            backLabel={t("general_cancel")}
            nextLabel={
              name ? t("addSection_wizard_submit_button") : t("addSection_wizard_next_button")
            }
          />

          <FormError error={error} />
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};
