import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { LocationDescriptor } from "history";

export const MainSectionBaseTile = ({
  to,
  shouldShowAttention,
  isLinkBlankTarget,
  shouldApplyPadding = true,
  isClickDisabled,
  dataTestId,
  children,
  onClick,
}: {
  to: LocationDescriptor;
  isLinkBlankTarget?: boolean;
  isClickDisabled?: boolean;
  shouldApplyPadding?: boolean;
  shouldShowAttention?: boolean;
  dataTestId?: string;
  onClick?: () => void;
  children: ReactNode;
}) => {
  const tileContainerClass = `flex lg:aspect-square xl:aspect-[70/61] lg:flex-col items-center justify-center rounded-lg lg:rounded-xl
    space-x-4 lg:space-x-0 ${
      shouldApplyPadding ? "p-4" : ""
    } bg-white overflow-hidden group h-24 lg:h-full ${shouldShowAttention ? "bg-red-50" : ""}`;

  if (!isClickDisabled) {
    return (
      <Link
        onClick={onClick}
        to={to}
        className="lg:w-full lg:h-full"
        {...(isLinkBlankTarget ? { target: "_blank", rel: "noopener noreferrer" } : {})}
      >
        <motion.div
          whileHover={{ scale: 1.03 }}
          data-testid={dataTestId}
          transition={{ type: "spring", stiffness: 98, damping: 10 }}
          className={twMerge("tile-border hover:shadow-md", tileContainerClass)}
        >
          {children}
        </motion.div>
      </Link>
    );
  }

  return <div className={tileContainerClass}>{children}</div>;
};
