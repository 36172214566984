import { useState, useMemo, useEffect } from "react";
import { Step, Wizard, useWizard } from "UIKit/Wizard";
import { AnalyticsEvents, UserLocation, userStore } from "@eolas-medical/core";
import { useBrowserCountry } from "Hooks/useBrowserCountry";
import useCreateSpace from "modules/spaces/data/useCreateSpace";
import { useNewSpaceInfoStep } from "./components/NewSpaceInfo/useNewSpaceInfoStep";
import { useCreateNewSpaceDetailsStep } from "./components/CreateNewSpaceDetails/useCreateNewSpaceDetailsStep";
import { useCreateSpaceZap } from "./components/hooks/useCreateSpaceZap";
import { useSpaceRequestedSuccessStep } from "./components/SpaceRequestedSuccess/useSpaceRequestedSuccess";
import { useConfirmSpaceTrialStep } from "./components/ConfirmSpaceTrial/useConfirmSpaceTrial";
import { trackEvent } from "API/Analytics";
import { webStore } from "Stores/WebStore";

type CreateSpaceSteps =
  | "newSpaceInfo"
  | "details"
  | "newUKSpaceRequested"
  | "newSpaceCreationSuccess";

interface WizardState {
  name: string;
  description: string;
  location?: UserLocation;
  url: string;
}

const CreateSpaceModal = () => {
  const { createSpace, creatingSpace } = useCreateSpace();
  const { browserCountryCode } = useBrowserCountry();
  const { wizardControl, submitWizard } = useWizard<CreateSpaceSteps>();
  const [spaceLocation, setSpaceLocation] = useState("");
  const [wizardState, setWizardState] = useState<WizardState>({
    name: "",
    description: "",
    location: { country: "", city: "" },
    url: "",
  });

  const {
    createSpaceZap,
    isError: spaceRequestError,
    isLoading: spaceRequestLoading,
  } = useCreateSpaceZap();

  const { userID = "" } = userStore;

  useEffect(() => {
    webStore.setShowCreateSpaceModal(false);
  }, []);

  const isUKLocation = useMemo(() => {
    if (!spaceLocation) return false;
    return spaceLocation.toLowerCase().includes(",uk");
  }, [spaceLocation]);

  const handleCreateSpace = () => {
    createSpace({
      ...wizardState,
      accessType: "private",
      countryCode: browserCountryCode,
      userId: userID,
    });
  };

  const handleSubmitSpaceDetails = ({
    spaceName,
    organisationName,
    location,
    description,
  }: {
    spaceName: string;
    organisationName?: string;
    location?: UserLocation;
    description: string;
  }) => {
    trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_NAME_COMPLETED);

    if (isUKLocation) {
      webStore.setShowCreateSpaceModal(false);
      createSpaceZap({
        spaceName,
        organisationName: organisationName ?? "",
      });
      trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_COMPLETED);
    } else {
      setWizardState((prev: WizardState) => ({
        ...prev,
        name: spaceName,
        location,
        description,
      }));
    }
  };

  const newSpaceInfoStep = useNewSpaceInfoStep({
    isLoading: creatingSpace,
  });

  const createNewSpaceDetailsStep = useCreateNewSpaceDetailsStep({
    onSubmit: handleSubmitSpaceDetails,
    onEnter: submitWizard,
    setSpaceLocation: setSpaceLocation,
    isLoading: spaceRequestLoading,
  });

  const spaceRequestedSuccessStep = useSpaceRequestedSuccessStep({
    spaceRequestError: spaceRequestError,
  });

  const confirmSpaceTrialStep = useConfirmSpaceTrialStep({
    isLoading: creatingSpace,
    spaceName: wizardState.name,
    description: wizardState.description,
    onSubmit: handleCreateSpace,
  });

  const steps: Step<CreateSpaceSteps>[] = [
    newSpaceInfoStep,
    createNewSpaceDetailsStep,
    isUKLocation ? spaceRequestedSuccessStep : confirmSpaceTrialStep,
  ];

  const getStepClassName = () => {
    return "max-w-4xl";
  };

  return (
    <Wizard
      getStepClassName={getStepClassName}
      wizardControl={wizardControl}
      steps={steps}
      showProgress
      showSteps
    />
  );
};

export default CreateSpaceModal;
