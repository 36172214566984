import { Step } from "UIKit";
import ConfirmSpaceTrial from "./ConfirmSpaceTrial";
import { useTranslation } from "react-i18next";

interface useConfirmSpaceTrialStepProps {
  isLoading?: boolean;
  spaceName: string;
  description?: string;
  onSubmit: () => void;
}

export const useConfirmSpaceTrialStep = ({
  onSubmit,
  isLoading,
  spaceName,
  description,
}: useConfirmSpaceTrialStepProps): Step<"newSpaceCreationSuccess"> => {
  const { t } = useTranslation();
  const title = t("confirmSpaceModal_final_step_title");
  return {
    title,
    name: "newSpaceCreationSuccess",
    component: <ConfirmSpaceTrial spaceName={spaceName} description={description} />,
    onSubmit,
    isLoading,
    nextLabel: t("confirmSpaceModal_nextLabel"),
  };
};
