import React, { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import {
  AnalyticsEvents,
  EolasFile,
  NationalResourcesClickPayload,
  NationalResourceEntityType,
  PageNames,
} from "@eolas-medical/core";

import { NavButton, PageTitle, InnerPageWrapper, Modal, Loader } from "UIKit";
import CommunityListItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { trackEvent } from "API/Analytics";
import { CommunitySectionType } from "../../ClinicalCalculators/types";
import { PatientLeafletQRModal } from "Pages/Spaces/pages/Space/pages/MiniApp/PatientLeaflets/components";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useGetNationalResourceByNationalBody } from "modules/nationalResources/data/useGetNationalResourceByNationalBody";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";

const PatientLeafletsBody: React.FC<RouteComponentProps> = observer(() => {
  const [modal, setModal] = useState<React.ReactNode>(null);

  const history = useHistory();

  const location = useLocation();
  const { body } = (location.state as { body: string }) || {};

  const { t } = useTranslation();

  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    removeFavouriteModal,
    closeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;
  const modalComponent = modal || shareModal || removeFavouriteModal;

  const handleCloseModal = () => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
    setModal(null);
  };

  const {
    searchInstanceId,
    handleClearSearch,
    handleSearchClick,
    flatListData,
    gettingNationalResources,
    isSearchLoading,
  } = useGetNationalResourceByNationalBody({
    entityType: NationalResourceEntityType.LEAFLET,
    nationalBody: body,
  });

  const handleClickLeaflet = React.useCallback((leaflet: EolasFile) => {
    trackEvent<NationalResourcesClickPayload>(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
      nationalResourceType: NationalResourceEntityType.LEAFLET,
      resourceName: leaflet.name,
    });
    setModal(<PatientLeafletQRModal file={leaflet} />);
  }, []);

  const renderItem = useCallback(
    ({ item }: { item: EolasFile }) => (
      <div className="mb-3">
        <CommunityListItem
          eolasFile={item}
          onSelectFile={handleClickLeaflet}
          sectionType={CommunitySectionType.NATIONAL_RESOURCE}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
          onShareFile={isShareFileEnabled ? () => handleOnShareFile(item) : undefined}
        />
      </div>
    ),
    [handleClickLeaflet, showRemoveFavouriteModal, isShareFileEnabled, handleOnShareFile],
  );

  return (
    <>
      <InnerPageWrapper>
        <PageTitle
          title={t("community_patientLeaflets_title")}
          eolasPageName={PageNames.NationalResourcesFiles}
        />
        <NavButton onClick={() => history.goBack()} />
        {gettingNationalResources ? (
          <Loader className="p-16" />
        ) : (
          <EolasVersatileList
            keyExtractor={(item) => item.id}
            className="space-y-6"
            isSearchLoading={isSearchLoading}
            items={flatListData}
            renderItem={renderItem}
            isSearchable
            droppableId="content-section-list"
            searchMode="click"
            onClickSearch={handleSearchClick}
            onClearSearch={handleClearSearch}
            searchInstanceId={searchInstanceId}
          />
        )}
      </InnerPageWrapper>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        {modalComponent}
      </Modal>
    </>
  );
});

export default PatientLeafletsBody;
