import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";

import { AppLevelSection, PageNames, Section, sectionStore } from "@eolas-medical/core";

import { useReorder } from "Hooks";
import { noFilesFound } from "Assets";
import { INameable } from "Utilities/helpers";
import { useSortOrder } from "Hooks/useSortOrder";
import { fromAwsJSON, removeLastUrlPath } from "Utilities";
import { useParamsDecoded } from "Utilities/useParamsDecoded";

import { NavButton, AddButton, PageTitle, LottieWithHeader, InnerPageWrapper, Modal } from "UIKit";

import { ChecklistTile } from "../components";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { getSpaceRoute } from "Pages/Spaces/helpers";
import { SortableList } from "shared/components/Lists";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

interface ChecklistSectionRouteParams {
  sectionID: string;
}

export type ChecklistItem = Section & INameable;

export const ChecklistSection: React.FC<RouteComponentProps<ChecklistSectionRouteParams>> =
  observer(({ match: { url } }) => {
    const { sectionID } = useParamsDecoded<ChecklistSectionRouteParams>();
    const [SortComponent, { orderBy, sortMethod }] = useSortOrder({
      initialOrder: "dragAndDrop",
      sortDateBy: "createdAt",
      isDraggable: true,
    });
    const { selectedSpace } = useSpacesContext();
    const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, closeShareModal, shareModal } =
      useShareFile();

    const {
      isFavouriteModalOpen,
      closeFavouriteModal,
      removeFavouriteModal,
      showRemoveFavouriteModal,
    } = useRemoveFavouriteModal();

    const isModalOpen = isFavouriteModalOpen || isShareModalOpen;

    const handleCloseModal = () => {
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
      }
      if (isShareModalOpen) {
        closeShareModal();
      }
    };

    const parentSection = sectionStore.getSection(sectionID);
    const { childrenOrder = [], parentID } = parentSection;

    const childrenReference = parentID ? sectionStore.getChildrenOrder(parentID) : null;
    const checklistSectionChildRef = childrenReference
      ? childrenReference.find((childRef) => childRef.id === sectionID)
      : null;

    const { t } = useTranslation();

    const isAdmin = sectionStore.isAdmin;

    const { isDragDisabled, onDragEnd } = useReorder(sectionID);

    const checkLists =
      childrenOrder?.reduce<Array<Section & INameable>>((sections, item) => {
        if ((isAdmin && item) || (item && item.disabled !== "true")) {
          const section = sectionStore.getSection(item.id);
          const {
            checklistTemplate: { checklistName },
          } =
            typeof section.metadata === "string"
              ? // eslint-disable-next-line quotes
                fromAwsJSON(section?.metadata || '"{}"')
              : section.metadata;
          return [...sections, { ...section, name: checklistName }];
        }
        return sections;
      }, []) ?? [];

    const orderedChecklists = sortMethod ? checkLists.slice().sort(sortMethod) : checkLists ?? [];

    return (
      <InnerPageWrapper>
        <FileBreadcrumbs
          shouldShowSpaceName
          mainSectionId={parentSection.mainSectionID}
          parentId={sectionID}
        />
        <PageTitle
          title={checklistSectionChildRef?.name ?? ""}
          eolasPageName={PageNames.miniAppFilesList(AppLevelSection.checklists)}
        />
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          {shareModal || removeFavouriteModal}
        </Modal>
        <NavButton to={removeLastUrlPath(url)} />

        {isAdmin ? (
          <Link
            to={`${getSpaceRoute(selectedSpace)}/content-management/${
              AppLevelSection.checklists
            }/${encodeURIComponent(sectionID)}/builder`}
            className="self-center"
          >
            <AddButton>{t("checklists_add_new_checklist")}</AddButton>
          </Link>
        ) : null}

        {isAdmin && orderedChecklists.length >= 2 ? (
          <span className="text-center text-grey-mid">{t("contentManagement_info")}</span>
        ) : null}

        {orderedChecklists.length > 1 ? SortComponent : null}

        {orderedChecklists?.length ? (
          <SortableList
            onDragEnd={onDragEnd}
            droppableId="checklist-list"
            isDragDisabled={isDragDisabled || orderBy !== "dragAndDrop"}
          >
            {orderedChecklists.map((item) => (
              <ChecklistTile
                url={url}
                key={item.id}
                checklist={item}
                isAdmin={isAdmin}
                onShare={isShareFileEnabled ? () => handleOnShareFile(item) : undefined}
                showRemoveFavouriteModal={showRemoveFavouriteModal}
              />
            ))}
          </SortableList>
        ) : (
          <LottieWithHeader
            animation={noFilesFound}
            lottieOptions={{ loop: true }}
            text={t("checklist_noChecklistsFound")}
          />
        )}
      </InnerPageWrapper>
    );
  });
