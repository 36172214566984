import { eolasLogger } from "@eolas-medical/core";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";
import { EolasContentMapFile } from "Pages/Spaces/stores/contentDb/types";

const NOT_AVAILABLE = "Not available";

type DownloadCSVNotification = "success" | "failure";

// Escape CSV value by wrapping it in quotes if it contains special characters
const escapeCsvValue = (value: string) => {
  // eslint-disable-next-line quotes
  if (value.includes(",") || value.includes("\n") || value.includes('"')) {
    // eslint-disable-next-line quotes
    return `"${value.replace(/"/g, '""')}"`; // Escape quotes by doubling them
  }
  return value;
};

// Generate CSV content for summaries
const generateSummaryCSVContent = (summaries: EolasContentMapFile[]): string => {
  if (summaries.length === 0) {
    eolasLogger.error(new Error("No data available in summaries"));
    return ""; // Return empty content if no summaries
  }

  const headers = [
    "Name",
    "Created At",
    "Updated At",
    "Is Shortcut",
    "Is Published",
    "Path",
    "Created By",
    "Updated By",
    "Expiry Date",
  ];

  // Create rows from the summaries array
  const rows = summaries.map((summary) => [
    escapeCsvValue(summary.name || NOT_AVAILABLE),
    escapeCsvValue(summary.createdAt ?? NOT_AVAILABLE),
    escapeCsvValue(summary.updatedAt ?? NOT_AVAILABLE),
    escapeCsvValue(summary.isShadowCopy ? "Yes" : "No"),
    escapeCsvValue(summary.isUnpublished ? "No" : "Yes"),
    escapeCsvValue(summary.path || NOT_AVAILABLE),
    escapeCsvValue(summary.createdBy || NOT_AVAILABLE),
    escapeCsvValue(summary.updatedBy),
    escapeCsvValue(summary.expiryDate),
  ]);

  const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

  return csvContent;
};

// Download CSV function that uses Blob to handle file downloads
const downloadCSV = (
  csvContent: string,
  fileName: string,
  showNotification: (outcome: DownloadCSVNotification) => void,
): void => {
  try {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);

    showNotification("success");
  } catch {
    eolasLogger.error(new Error("Failed to export content CSV"));
    showNotification("failure");
  }
};

// Custom hook to handle the export of summaries to CSV
export const useExportSummaryCsv = () => {
  const { showNotification } = useNotifications();
  const { t } = useTranslation();

  const handleExportSummaries = (summaries: EolasContentMapFile[], fileName: string) => {
    const csvContent = generateSummaryCSVContent(summaries);
    downloadCSV(csvContent, fileName, (outcome) => {
      showNotification({
        type: outcome === "success" ? "success" : "error",
        autoHideTimeout: 5000,
        description:
          outcome === "success" ? t("export_summaries_success") : t("export_summaries_failure"),
      });
    });
  };

  return { handleExportSummaries };
};
