import { useTranslation, Trans } from "react-i18next";
import { newSpaceImage } from "Assets";
import { Text, Button, InnerModalWrapper, ModalHeader, Title, ModalBody } from "UIKit";

const PostSpaceCreationModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <InnerModalWrapper className="max-w-4xl">
      <ModalHeader className="flex flex-col items-start">
        <Title level={5}>{t("postSpaceCreationModal_title")}</Title>
      </ModalHeader>

      <ModalBody className="overflow-y-auto overflow-x-hidden flex-grow max-h-80vh pt-0">
        <div className="w-full overflow-y-auto bg-white">
          <img className="mb-12" src={newSpaceImage} alt="" />

          <Text level={2} className="mt-4 text-gray-700">
            <Trans
              i18nKey="postSpaceCreationModal_welcome_message"
              components={{ 1: <span className="font-semibold" /> }}
            />
          </Text>

          <div className="my-6">
            <Text level={2} className="mb-4">
              {t("postSpaceCreationModal_what_happens_next")}
            </Text>
            <ul className="list-disc list-inside space-y-2 text-gray-600">
              <li>
                <Text level={2}>
                  <Trans
                    i18nKey="postSpaceCreationModal_knowledge_base"
                    components={{
                      1: (
                        <a
                          href="https://support.eolasmedical.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-semibold underline text-black"
                        />
                      ),
                    }}
                  />
                </Text>
              </li>
              <li>
                <Text level={2}>
                  <Trans
                    i18nKey="postSpaceCreationModal_add_content"
                    components={{
                      1: (
                        <a
                          href="https://support.eolasmedical.com/adding-an-editor-item-to-a-generic-content-repository"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-semibold underline text-black"
                        />
                      ),
                    }}
                  />
                </Text>
              </li>
              <li>
                <Text level={2}>
                  <Trans
                    i18nKey="postSpaceCreationModal_invite_team"
                    components={{
                      1: (
                        <a
                          href="https://support.eolasmedical.com/invite-multiple-users-with-a-qr-code-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-semibold underline text-black"
                        />
                      ),
                    }}
                  />
                </Text>
              </li>
              <li>
                <Text level={2}>
                  <Trans
                    i18nKey="postSpaceCreationModal_explore_features"
                    components={{
                      1: (
                        <a
                          href="https://support.eolasmedical.com/admin-guide-general-overview-of-the-eolas-medical-platform"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-semibold underline text-black"
                        />
                      ),
                    }}
                  />
                </Text>
              </li>
              <li>
                <Text level={2}>
                  <Trans
                    i18nKey="postSpaceCreationModal_track_usage"
                    components={{
                      1: (
                        <a
                          href="https://support.eolasmedical.com/understanding-the-new-analytics-feature-in-the-admin-panel-beta"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-semibold underline text-black"
                        />
                      ),
                    }}
                  />
                </Text>
              </li>
            </ul>
          </div>

          <div className="my-6">
            <Text level={2} className="mb-4">
              {t("postSpaceCreationModal_after_trial")}
            </Text>
            <ul className="list-disc list-inside space-y-2 text-gray-600">
              <li>
                <Text level={2}>{t("postSpaceCreationModal_flexible_plans")}</Text>
              </li>
              <li>
                <Text level={2}>{t("postSpaceCreationModal_trial_reminder")}</Text>
              </li>
              <li className="font-semibold">
                <Text level={2}>{t("postSpaceCreationModal_no_commitment")}</Text>
              </li>
            </ul>
          </div>

          <div className="mt-6 flex justify-center">
            <Button variant="solid" className="px-12 py-3 text-white rounded-sm" onClick={onClose}>
              {t("postSpaceCreationModal_get_started")}
            </Button>
          </div>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default PostSpaceCreationModal;
