import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";

import {
  AnalyticsEvents,
  CommunityLevelSection,
  EolasFile,
  NationalResourcesClickPayload,
  NationalResourceEntityType,
  PageNames,
} from "@eolas-medical/core";

import { NavButton, PageTitle, InnerPageWrapper, Loader, Modal } from "UIKit";

import { snakeCase } from "lodash";
import useNationalBodies, { NationalBody } from "modules/nationalResources/data/useNationalBodies";
import { trackEvent } from "API/Analytics";
import { useRecordMainSectionVisit, useSelectFile } from "Hooks";
import useSearchNationalResources from "modules/nationalResources/data/useSearchNationalResources";
import KnowledgeSectionItem from "Pages/Knowledge/components/KnowledgeSectionItem/KnowledgeSectionItem";
import { CommunitySectionType } from "../ClinicalCalculators/types";
import { BaseAppRoute } from "Utilities/types";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { isEolasFile } from "modules/contentRepository/typeguards";
import { EolasSectionTile } from "UIKit/EolasSectionTile/EolasSectionTile";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { useCallback } from "react";

const NationalGuidelines: React.FC<RouteComponentProps> = observer(() => {
  const history = useHistory();
  const { onSelectFile } = useSelectFile();
  useRecordMainSectionVisit({
    mainSectionType: CommunityLevelSection.nationalGuidelines,
  });

  const { nationalBodies, gettingNationalBodies } = useNationalBodies({
    entityType: NationalResourceEntityType.GUIDELINE,
  });

  const {
    handleSearchClick,
    searchInstanceId,
    handleClearSearch,
    searchResults,
    isSearchActive,
    isSearchLoading,
  } = useSearchNationalResources({
    communitySection: CommunityLevelSection.nationalGuidelines,
    nationalBodies,
    entityType: NationalResourceEntityType.GUIDELINE,
  });

  const { t } = useTranslation();

  const handleClickGuideline = (guideline: EolasFile) => {
    trackEvent(AnalyticsEvents.NATIONAL_RESOURCE_SELECTED, {
      nationalResourceType: NationalResourceEntityType.GUIDELINE,
      resourceName: guideline.name,
    } as NationalResourcesClickPayload);
    onSelectFile(guideline);
  };

  const renderItem = ({ item }: { item: EolasFile | NationalBody }) => (
    <div className="mb-3">
      {isEolasFile(item) ? (
        <KnowledgeSectionItem
          eolasFile={item}
          onSelectFile={handleClickGuideline}
          sectionType={CommunitySectionType.NATIONAL_RESOURCE}
          onShareFile={isShareFileEnabled ? () => handleOnShareFile(item) : undefined}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      ) : (
        <EolasSectionTile
          variant="nationalBody"
          title={item.name}
          onClick={() => {
            const snakeCaseBody = snakeCase(item.name);
            history.push({
              pathname: `/${BaseAppRoute.knowledge}/nationalGuidelines/${snakeCaseBody}`,
              state: { body: item.name },
            });
          }}
        />
      )}
    </div>
  );
  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, shareModal, closeShareModal } =
    useShareFile();
  const {
    showRemoveFavouriteModal,
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = isShareModalOpen || isFavouriteModalOpen;
  const onCloseModal = useCallback(() => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }
    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }
  }, [closeShareModal, isShareModalOpen, isFavouriteModalOpen, closeFavouriteModal]);
  return (
    <>
      <InnerPageWrapper>
        <Modal open={isModalOpen} onClose={onCloseModal}>
          {shareModal || removeFavouriteModal}
        </Modal>
        <PageTitle
          title={t("community_nationalGuidelines_title")}
          eolasPageName={PageNames.NationalResourcesSections}
        />
        <NavButton onClick={() => history.goBack()} />
        {gettingNationalBodies ? (
          <Loader className="p-16" />
        ) : (
          <EolasVersatileList
            key={isSearchActive ? "search-list" : "default-list"}
            className="space-y-6"
            isSearchLoading={isSearchLoading}
            items={isSearchActive ? searchResults : nationalBodies}
            renderItem={renderItem}
            isSearchable
            searchMode="click"
            onClickSearch={handleSearchClick}
            onClearSearch={handleClearSearch}
            searchInstanceId={searchInstanceId}
          />
        )}
      </InnerPageWrapper>
    </>
  );
});

export default NationalGuidelines;
