import { ProfileVerificationStatus } from "@eolas-medical/core";
import { PendingIcon, UnverifiedIcon, VerifiedIcon } from "Assets";

export const getVerificationStatusIcon = (status: ProfileVerificationStatus) => {
  switch (status) {
    case ProfileVerificationStatus.UNVERIFIED:
      return <UnverifiedIcon />;
    case ProfileVerificationStatus.PENDING:
      return <PendingIcon />;
    case ProfileVerificationStatus.VERIFIED:
      return <VerifiedIcon />;
    default:
      return <UnverifiedIcon />;
  }
};

export const getVerificationStatusClass = (
  status: ProfileVerificationStatus = ProfileVerificationStatus.UNVERIFIED,
) => {
  let icon, className;

  if (status === "UNVERIFIED") {
    className = "text-red-500 bg-red-50 text-xs max-w-[70%]";
    icon = <UnverifiedIcon />;
  } else if (status === "PENDING") {
    className = "text-yellow-700 bg-yellow-100 text-xs max-w-[70%]";
    icon = <PendingIcon />;
  } else if (status === "VERIFIED") {
    icon = <VerifiedIcon />;
    className = "text-green-500 bg-green-50 text-xs max-w-[70%]";
  }

  return { icon, className };
};
