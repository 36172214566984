import { ArrowLeftIcon, CheckIcon } from "Assets";
import { IconButton } from "UIKit/Button";
import { FormElement, Input } from "UIKit/FormElements";
import { IconPicker } from "UIKit/IconPicker";
import { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { SectionData } from "./types";
import { Icon } from "UIKit/IconPicker/types";
import { useTranslation } from "react-i18next";

interface ConfigureSectionStepProps {
  control: Control<{ name: string; description?: string; iconName: string }>;
  nameInputLabel: string;
  descriptionInputLabel?: string;
  nameInputPlaceholder: string;
  descriptionInputPlaceholder: string;
  onSetIcon: (icon: string) => void;
  onEnter: () => void;
  isLoading: boolean;
  section?: SectionData;
  iconsList: Icon[];
  iconPickerTitle: string;
  showIconPicker: boolean;
  showDescription: boolean;
  onAddingIcon?: (isAddingIcon: boolean) => void;
  isDescriptionRequired?: boolean;
}

export const ConfigureSectionStep = ({
  control,
  nameInputPlaceholder,
  nameInputLabel,
  descriptionInputLabel,
  descriptionInputPlaceholder,
  isLoading,
  iconsList,
  section,
  iconPickerTitle,
  showIconPicker,
  showDescription = false,
  onSetIcon,
  onEnter,
  onAddingIcon,
  isDescriptionRequired = true,
}: ConfigureSectionStepProps) => {
  const [isAddingIcon, setIsAddingIcon] = useState<boolean>(showIconPicker);
  const [selectedIconUrl, setSelectedIconUrl] = useState<string | undefined>(
    section?.iconUrl || section?.iconName,
  );
  const { t } = useTranslation();
  useEffect(() => {
    setIsAddingIcon(showIconPicker);
  }, [showIconPicker]);

  useEffect(() => {
    onAddingIcon?.(isAddingIcon);
  }, [isAddingIcon, onAddingIcon]);

  if (isAddingIcon) {
    return (
      <div className="-mt-12">
        <IconButton
          className="my-4 mx-auto"
          icon={<ArrowLeftIcon />}
          onClick={() => setIsAddingIcon(false)}
        />

        <IconPicker
          iconsList={iconsList}
          defaultShowIcons
          iconURL={selectedIconUrl}
          iconContainerClassname="max-h-50vh"
          onClick={(icon) => {
            setSelectedIconUrl(icon.url);
            setIsAddingIcon(false);
            onSetIcon(icon.fileName);
          }}
          label={t("section_icon")}
        />
      </div>
    );
  }

  return (
    <div data-testid="configure-section-modal">
      <form onSubmit={onEnter}>
        <FormElement required id="name" control={control} label={nameInputLabel}>
          {(fieldProps, { isValid }) => {
            const { value, ...rest } = fieldProps;
            return (
              <Input
                {...rest}
                value={value || ""}
                size="lg"
                disabled={isLoading}
                data-testid="configure-section-modal-name-input"
                placeholder={nameInputPlaceholder}
                iconRight={isValid ? <CheckIcon className="text-green" /> : undefined}
              />
            );
          }}
        </FormElement>

        {showDescription ? (
          <FormElement
            required={isDescriptionRequired}
            id="description"
            control={control}
            label={descriptionInputLabel}
          >
            {(fieldProps, { isValid }) => {
              const { value, ...rest } = fieldProps;
              return (
                <Input
                  {...rest}
                  value={value || ""}
                  size="lg"
                  disabled={isLoading}
                  data-testid="configure-section-modal-description-input"
                  placeholder={descriptionInputPlaceholder}
                  iconRight={isValid ? <CheckIcon className="text-green" /> : undefined}
                />
              );
            }}
          </FormElement>
        ) : null}
        <FormElement required id="icon" control={control} labelClassName="font-semibold">
          {() => (
            <div>
              <IconPicker
                iconURL={selectedIconUrl || ""}
                onClick={() => {
                  return;
                }}
                iconContainerClassname="h-50vh"
                onOpen={() => setIsAddingIcon(true)}
                iconListClassname="pr-4 sm:pr-12 pb-4"
                label={iconPickerTitle}
              />
            </div>
          )}
        </FormElement>
      </form>
    </div>
  );
};

export default ConfigureSectionStep;
