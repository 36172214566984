import { MonographType, PageNames } from "@eolas-medical/core";
import { useGetBnfSearchParams } from "../../../hooks/useGetBnfSearchParams";
import { InnerPageWrapper, Loader } from "UIKit";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { MonographContextProvider } from "../../../contexts/ MonographTypeContext";
import { SummaryGroup } from "../AdditionalContentHome/components/SummaryGroup";
import { useProcessMedicalDevices } from "./hooks/useProcessMedicalDevices";
import { DevicesSection } from "./components/DevicesSection";
import { BrandItem } from "./components/BrandItem";

const MedicalDevices = ({ type }: { type: MonographType }) => {
  const { idOrSid } = useGetBnfSearchParams();
  const {
    isInitialLoading,
    activeMedicalDeviceSubsection,
    medicalDeviceSections,
    goBackMedicalDevice,
    makeOnClick,
    title,
  } = useProcessMedicalDevices({ type, idOrSid });

  return (
    <InnerPageWrapper className="space-y-3 lg:space-y-6">
      <BnfHeader
        title={title}
        type={type}
        goBackBehaviourPreference="goBackHistory"
        overrideGoBackCallback={goBackMedicalDevice}
        eolasPageName={PageNames.BnfMedicalDevice}
      />
      <MonographContextProvider type={type}>
        {isInitialLoading ? <Loader className="bg-transparent h-30vh" /> : <></>}
        {!activeMedicalDeviceSubsection && medicalDeviceSections.length ? (
          <SummaryGroup
            items={medicalDeviceSections}
            makeOnClick={makeOnClick}
            title={title}
            itemTitleProperty="title"
          />
        ) : (
          <>
            <DevicesSection devices={activeMedicalDeviceSubsection?.devices} />
            {activeMedicalDeviceSubsection?.brands.length
              ? activeMedicalDeviceSubsection.brands.map((item, index) => (
                  <BrandItem brand={item} key={index.toString()} />
                ))
              : null}
          </>
        )}
      </MonographContextProvider>
    </InnerPageWrapper>
  );
};

export const MedicalDevicesAdult = () => <MedicalDevices type="bnf" />;
export const MedicalDevicesChild = () => <MedicalDevices type="bnfc" />;
