import { makeAutoObservable } from "mobx";

class KnowledgeSearchStore {
  textToHighlight: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setTextToHighlight = (newText: string) => {
    this.textToHighlight = newText;
  };

  cleartextToHighlight = () => {
    this.textToHighlight = "";
  };
}

export const knowledgeSearchStore = new KnowledgeSearchStore();
