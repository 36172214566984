import { makeObservable, observable, action } from "mobx";
import { v4 as uuidv4 } from "uuid";

class SearchBoxStore {
  constructor() {
    makeObservable(this);
  }

  @observable private searchBoxText: Record<string, string> = {};
  @observable private isFocusedMap: Record<string, boolean> = {};
  @observable private activeQueryMap: Record<string, boolean> = {};

  @action makeSearchBoxInstance = () => {
    const id = uuidv4();
    this.searchBoxText[id] = "";
    this.isFocusedMap[id] = false;
    this.activeQueryMap[id] = false;
    return id;
  };

  @action removeSearchBoxInstance = (id: string) => {
    delete this.searchBoxText[id];
    delete this.isFocusedMap[id];
  };

  @observable getText = (id: string) => {
    return this.searchBoxText[id] ?? "";
  };

  @observable getIsQueryActive = (id: string) => {
    return this.activeQueryMap[id] ?? false;
  };

  @observable getIsFocused = (id: string) => {
    return this.isFocusedMap[id] ?? false;
  };

  @action setText = (newText: string, id: string) => {
    if (!newText) {
      this.searchBoxText[id] = "";
      this.activeQueryMap[id] = false;
      return;
    }

    if (this.activeQueryMap[id] === false) {
      this.activeQueryMap[id] = true;
    }

    this.searchBoxText[id] = newText;
  };

  @action setIsFocused = (newValue: boolean, id: string) => {
    this.isFocusedMap[id] = newValue;
  };
  getSearchBoxText = (id: string) => {
    return this.searchBoxText[id];
  };
}

export const searchBoxStore = new SearchBoxStore();
