import { useGetBnfSearchParams } from "../../../hooks/useGetBnfSearchParams";
import { InnerPageWrapper, Loader } from "UIKit";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { MonographContextProvider } from "../../../contexts/ MonographTypeContext";
import { useProcessWoundCare } from "./hooks/useProcessWoundCare";
import { removeTags } from "modules/bnf/helpers";
import { WoundCareHistoryItem } from "./components/WoundCareHistoryItem";
import { WoundCareTopLevelItem } from "./components/WoundCareTopLevelItem";
import { PageNames } from "@eolas-medical/core";

// No woundcare for BNFC, so define here:
const type = "bnf";

const WoundCarePage = () => {
  const { idOrSid } = useGetBnfSearchParams();
  const { isLoading, historyItem, topLevelItem, topLevelTitle, goBackOverride, makeOnClick } =
    useProcessWoundCare({ idOrSid, type });

  let title: string | undefined;

  if (historyItem) {
    title = removeTags(historyItem.title);
  } else {
    title = topLevelTitle;
  }

  return (
    <InnerPageWrapper className="space-y-3 lg:space-y-6">
      <BnfHeader
        title={title}
        type={type}
        goBackBehaviourPreference="goBackHistory"
        overrideGoBackCallback={goBackOverride}
        eolasPageName={PageNames.BnfWoundCareDetails}
      />
      <MonographContextProvider type={type}>
        {isLoading ? <Loader className="bg-transparent h-30vh" /> : null}
        {historyItem ? (
          <WoundCareHistoryItem historyItem={historyItem} makeOnClick={makeOnClick} />
        ) : (
          <WoundCareTopLevelItem
            topLevelItem={topLevelItem}
            makeOnClick={makeOnClick}
            title={title}
          />
        )}
      </MonographContextProvider>
    </InnerPageWrapper>
  );
};

export const WoundCarePageAdult = () => <WoundCarePage />;
