import { useTranslation } from "react-i18next";
import SearchResultItem from "../SearchResultItem/SearchResultItem";
import { SearchResult } from "../../types";
import useSearchResultConfig from "../../hooks/useSearchResultConfig";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import CopilotSearchResultItem from "../CopilotSearchResultItem/CopilotSearchResultItem";
import {
  isKnowledgeSearchResult,
  isLocalSearchResult,
} from "Components/MasterSearch/functions/typeguards";
import { useShareSearchResult } from "Components/MasterSearch/hooks/useShareSearchResult/useShareSearchResult";

interface SearchResultListProps {
  results: SearchResult[];
  onClickResult: (result: SearchResult) => void;
  suggestedQueries?: JSX.Element | null;
  isAdminSearch?: boolean;
}

const SearchResultList = ({
  onClickResult,
  results,
  suggestedQueries,
  isAdminSearch,
}: SearchResultListProps) => {
  const { t } = useTranslation();
  const { getSearchResultProps } = useSearchResultConfig();
  const { getIconComponent } = useIconComponent("h-8 w-8");

  const { checkIsSearchResultShareable, handleOnShareKnowledgeResult, handleOnShareLocalResult } =
    useShareSearchResult();

  return (
    <div
      className="flex flex-col bg-grey-50 rounded-xl p-2 space-y-2 overflow-y-auto max-h-60vh mt-6"
      data-testid="master-search-results-list"
    >
      {results.map((result, index) => {
        const {
          searchTypeSection,
          mainSectionName,
          pillTextColor,
          pillBgColor,
          color,
          pillIcon,
          hero,
          answer,
        } = getSearchResultProps(result);

        const PillIcon = pillIcon;
        const Icon = result.iconName ? getIconComponent(result.iconName) : undefined;

        let component = null;
        if (
          isLocalSearchResult(result) &&
          isAdminSearch &&
          result.iconName === "medusaMedications"
        ) {
          // We do not want to show the medication results in the admin search
          return null;
        } else if (isLocalSearchResult(result)) {
          component = (
            <div key={result.id} onClick={() => onClickResult(result)}>
              <SearchResultItem
                item={result}
                icon={Icon}
                title={result.title}
                mainSectionName={mainSectionName}
                canShareResult={checkIsSearchResultShareable(result)}
                onShareResult={() => handleOnShareLocalResult(result)}
              />
            </div>
          );
        } else if (isKnowledgeSearchResult(result)) {
          component = (
            <div key={result.id} onClick={() => onClickResult(result)}>
              <CopilotSearchResultItem
                title={result.title}
                resultItem={result}
                icon={Icon}
                hero={hero}
                answer={answer}
                mainSectionName={mainSectionName}
                searchTypeSection={t(searchTypeSection)}
                pillBgColor={`${color}-${pillBgColor}`}
                pillTextColor={`${color}-${pillTextColor}`}
                pillIcon={
                  PillIcon ? <PillIcon className={`w-4 h-4 text-${pillTextColor}`} /> : null
                }
                onShareResult={
                  checkIsSearchResultShareable(result)
                    ? () => handleOnShareKnowledgeResult(result)
                    : undefined
                }
              />
            </div>
          );

          if (index === results.findIndex((r) => r.searchTypeSection === "knowledge") + 2) {
            component = (
              <>
                {component}
                <div key="suggested_searches">{suggestedQueries}</div>
              </>
            );
          }
        }

        return component;
      })}
    </div>
  );
};

export default SearchResultList;
