import { motion } from "framer-motion";

import { InnerPageWrapper, Modal } from "UIKit";
import GridContainer from "UIKit/GridContainer/GridContainer";
import MasterSearch from "Components/MasterSearch/MasterSearch";
import ViewSpaceDetailsModal from "./components/ViewSpaceDetailsModal/ViewSpaceDetailsModal";
import AccessQRCode from "./components/AccessQRCode/AccessQRCode";
import SpaceDetails from "./components/SpaceDetails/SpaceDetails";
import EditSpaceDetailsWizard from "./components/EditSpaceDetailsWizard/EditSpaceDetailsWizard";
import MainSectionList from "Pages/Spaces/pages/Space/components/MainSectionList/MainSectionList";
import { observer } from "mobx-react-lite";
import { useSpaceDetailsModal } from "./hooks/useSpaceDetailsModal";
import { useSpaceOrgMainSections } from "modules/spaces/data/useSpaceOrgMainSections";
import { usePageAnalytics } from "Hooks/usePageAnalytics";
import { PageNames } from "@eolas-medical/core";

const variants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};
const Space = observer(() => {
  const {
    modalState,
    isAdminUser,
    isPublic,
    updatingSpace,
    handleSpaceUpdated,
    setModalState,
    selectedSpace,
    handleCloseModal,
    canCloseModal,
    updateSpaceSuccess,
  } = useSpaceDetailsModal();

  const { activatedMainSections } = useSpaceOrgMainSections({ activeTab: "spaces" });

  usePageAnalytics({ eolasPageName: PageNames.homePage("space") });

  if (!selectedSpace) {
    return null;
  }

  return (
    <InnerPageWrapper>
      <div className="space-y-6 flex flex-col mb-10">
        <SpaceDetails
          onClickEditSpace={() => setModalState("editSpace")}
          onClickViewSpace={() => setModalState("viewSpace")}
          isAdminUser={isAdminUser}
          isPublic={isPublic}
          selectedSpace={selectedSpace}
        />
        <motion.div
          className="mt-8 w-full"
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          <MasterSearch />
        </motion.div>
      </div>

      <GridContainer>
        <MainSectionList mainSectionList={activatedMainSections} />
      </GridContainer>

      <Modal
        open={modalState !== null}
        onClose={handleCloseModal}
        hasCloseIcon={false}
        shouldCloseOnOverlayClick={canCloseModal}
        shouldCloseOnEsc={canCloseModal}
      >
        {modalState === "editSpace" ? (
          <EditSpaceDetailsWizard
            onUpdate={handleSpaceUpdated}
            isLoading={updatingSpace}
            selectedSpace={selectedSpace}
            isSuccess={updateSpaceSuccess}
          />
        ) : null}
        {modalState === "viewSpace" ? (
          <ViewSpaceDetailsModal
            onClose={() => handleCloseModal()}
            imageUrl={selectedSpace?.image?.url}
          />
        ) : null}
        {modalState === "shareSpace" ? <AccessQRCode onClose={handleCloseModal} /> : null}
      </Modal>
    </InnerPageWrapper>
  );
});

export default Space;
