import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import {
  Input,
  Title,
  Button,
  FormError,
  ModalBody,
  FormElement,
  ModalHeader,
  InnerModalWrapper,
} from "UIKit";

import { useIsScrollable } from "Hooks";
import { UserUpdateIcon } from "Assets";

import { LoginCredentials } from "../types";
import { useCreateAccountStep } from "../hooks";
import { PasswordInput } from "../../componentos/PasswordInput";
import { PasswordInputValidator } from "../../componentos/PasswordInputValidator";

interface CreateAccountStepProps {
  email?: string;
  onCreateAccountSuccess(loginCredentials: LoginCredentials): void;
}

export const CreateAccountStep: React.FC<CreateAccountStepProps> = ({
  email,
  onCreateAccountSuccess,
}: CreateAccountStepProps) => {
  const { t } = useTranslation();
  const { ref, ScrollHint } = useIsScrollable<HTMLFormElement>();
  const { control, formMethods, isLoading, error, onSubmit } = useCreateAccountStep(
    onCreateAccountSuccess,
    email,
  );

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex-col">
        <UserUpdateIcon className="h-20 w-20 mb-4" />
        <Title level={5}>{t("createAccount_title")}</Title>
        <ScrollHint />
      </ModalHeader>

      <ModalBody className="overflow-hidden">
        <FormProvider {...formMethods}>
          <form
            ref={ref}
            onSubmit={onSubmit}
            className="flex flex-col space-y-4 overflow-y-auto px-2"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormElement
                required
                id="given_name"
                control={control}
                data-testid="create-name-input"
                label={t("acceptInvitation_first_name_label")}
              >
                {(field) => (
                  <Input {...field} placeholder={t("acceptInvitation_first_name_placeholder")} />
                )}
              </FormElement>

              <FormElement
                required
                id="family_name"
                control={control}
                data-testid="create-surname-input"
                label={t("acceptInvitation_surname_label")}
              >
                {(field) => (
                  <Input {...field} placeholder={t("acceptInvitation_surname_placeholder")} />
                )}
              </FormElement>
            </div>

            {!email ? (
              <FormElement
                required
                id="email"
                control={control}
                label={t("createAccount_email")}
                data-testid="create-email-input"
              >
                {(field) => (
                  <Input {...field} placeholder={t("createAccount_forgotPassword_placeholder")} />
                )}
              </FormElement>
            ) : null}

            <PasswordInputValidator />

            <PasswordInput
              id="confirmPassword"
              testID="confirm-password-input"
              label={t("acceptInvitation_confirm_password_label")}
              placeholder={t("acceptInvitation_confirm_password_placeholder")}
            />

            <Button
              type="submit"
              isLoading={isLoading}
              data-testid="create-submit"
              style={{ minHeight: "2.5rem" }}
            >
              Submit
            </Button>

            <FormError error={error} />
          </form>
        </FormProvider>
      </ModalBody>
    </InnerModalWrapper>
  );
};
