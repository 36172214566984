import { useCallback, useState } from "react";

import { useLaunchDarkly } from "Contexts";
import { useS3FileUploadV2 } from "Hooks";
import { useSpacesContext } from "modules/spaces";
import { sectionStore } from "@eolas-medical/core";
import { LDFlagNames } from "Utilities/types";

type UploadMediaProps = {
  mainSectionId: string;
  shouldAlwaysUploadAsPublic?: boolean;
  mediaName?: string;
  blob: File;
};

export const useUploadBlob = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadFileV2 = useS3FileUploadV2();
  const { isPublic } = useSpacesContext();

  const { flags } = useLaunchDarkly();

  const isLocationPublic = isPublic && Boolean(flags[LDFlagNames.PUBLIC_FILES]);

  const uploadBlob = useCallback(
    async ({
      mainSectionId,
      shouldAlwaysUploadAsPublic,
      blob,
    }: UploadMediaProps): Promise<
      | { type: "public"; url: string; isLegacy: false }
      | { type: "private"; s3Key: string; isLegacy: boolean }
    > => {
      if (shouldAlwaysUploadAsPublic || isLocationPublic) {
        const isOrganisationFile = sectionStore.hospitalSectionsOrder.some(
          ({ id }) => id === mainSectionId,
        );
        const { publicUrl } = await uploadFileV2({
          file: blob,
          mainSectionId,
          onUploadProgress: setUploadProgress,
          isOrganisationFile,
          isPublic: true,
        });

        if (!publicUrl) {
          throw new Error("Unable to get public url in mutateContentItem");
        }
        return { type: "public", url: publicUrl, isLegacy: false };
      } else {
        const { mediaId } = await uploadFileV2({
          file: blob,
          mainSectionId,
          onUploadProgress: setUploadProgress,
        });
        return { type: "private", s3Key: mediaId, isLegacy: false };
      }
    },
    [isLocationPublic, uploadFileV2],
  );

  return { uploadBlob, setUploadProgress, uploadProgress, isLocationPublic };
};
