import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { sectionStore, contentClient, eolasLogger, hasStringProp } from "@eolas-medical/core";
import { useLaunchDarkly, useSentry } from "Contexts";
import { useRequestStatus } from "Utilities";
import { ChildReference, LDFlagNames } from "Utilities/types";
import useGetIconUrl from "Hooks/useGetIconUrl/useGetIconUrl";
import { Icon } from "UIKit/IconPicker/types";
import { getErrorMessage } from "Utilities/helpers";
import { useRefetchAppData } from "Hooks";
import { DEFAULT_SECTION_ICON_NAME } from "UIKit";

export const PUBLISH_SECTION = gql`
  mutation PublishSection($parentID: String!, $input: PublishSectionInput!) {
    publishSection(parentID: $parentID, input: $input) {
      id
      childrenOrder {
        id
        type
        icon
        name
        disabled
        description
      }
    }
  }
`;

export const UPDATE_SECTION_INFO = gql`
  mutation UpdateSectionInfo($parentID: String!, $input: UpdateSectionInfoInput!) {
    updateSectionInfo(parentID: $parentID, input: $input) {
      id
      childrenOrder {
        id
        type
        icon
        name
        disabled
        description
      }
    }
  }
`;

type SectionFormShape = {
  name: string;
  icon: string;
  description: string;
};

const mapToDefaultValues = (sectionReference?: ChildReference): SectionFormShape => {
  return {
    name: sectionReference?.name ?? "",
    description: sectionReference?.description ?? "",
    icon: sectionReference?.icon || DEFAULT_SECTION_ICON_NAME,
  };
};

export const useAddSection = (parentID: string, sectionReference?: ChildReference) => {
  const { addBreadcrumb } = useSentry();
  const { getIconUrl } = useGetIconUrl();

  const [publishSection] = useMutation(PUBLISH_SECTION);
  const [updateSectionInfo] = useMutation(UPDATE_SECTION_INFO);

  const [isAddingIcon, setIsAddingIcon] = useState(false);
  const { error, isLoading, isSuccessful, setRequestStatus } = useRequestStatus();
  const { flags } = useLaunchDarkly();
  const useAppServicesEndpoints = flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false;

  const { control, handleSubmit, setValue, watch } = useForm<SectionFormShape>({
    defaultValues: mapToDefaultValues(sectionReference),
  });

  const { refetch } = useRefetchAppData();

  const [name, iconFileName] = watch(["name", "icon", "description"]);

  const isFormComplete = useMemo(() => {
    return !!name && !!iconFileName;
  }, [name, iconFileName]);

  const iconURL = useMemo(() => getIconUrl(iconFileName), [iconFileName, getIconUrl]);

  const selectIcon = (icon: Icon) => {
    setValue("icon", icon.fileName);
  };

  const onPublishSection = ({ name, description, icon }: SectionFormShape) => {
    addBreadcrumb({
      category: "Publish Section",
      message: "publishing section",
      data: {
        name,
      },
    });

    setRequestStatus({ status: "pending", error: "" });

    const createSectionPromise = useAppServicesEndpoints
      ? publishSection({
          variables: {
            parentID,
            input: {
              name,
              icon,
              description,
              type: "section",
            },
          },
        })
      : contentClient.createSectionRest({
          name,
          icon,
          description,
          parentId: parentID,
          mainSectionId: parentID,
          identity: "miniApp",
          childrenType: "file",
        });

    createSectionPromise
      .then(async () => {
        setRequestStatus({ status: "success", error: "" });
        refetch();
      })
      .catch((error) => {
        eolasLogger.error(error);
        setRequestStatus({
          status: "error",
          error: getErrorMessage({ error }),
        });
      });
  };

  const onEditSection = async ({ name, description, icon }: SectionFormShape) => {
    if (!sectionReference) throw new Error("Section must exist when editing it");

    addBreadcrumb({
      category: "Edit Section",
      message: "editing section",
      data: {
        name,
        id: sectionReference.id,
      },
    });

    const { parentID, ownerID } = sectionStore.getSection(sectionReference.id)!;

    setRequestStatus({ status: "pending", error: "" });

    const updateSectionPromise = useAppServicesEndpoints
      ? updateSectionInfo({
          variables: {
            parentID: sectionReference?.type === "mainSection" ? ownerID : parentID,
            input: {
              name,
              icon,
              description,
              id: sectionReference.id,
            },
          },
        })
      : contentClient.updateSectionRest({
          name,
          icon,
          description,
          id: sectionReference.id,
        });

    updateSectionPromise
      .then(async () => {
        refetch();
        setRequestStatus({ status: "success", error: "" });
      })
      .catch((error) => {
        eolasLogger.error(error);
        setRequestStatus({
          status: "error",
          error: hasStringProp(error, "message") ? error.message : "unknown error",
        });
      });
  };

  const onSubmit = handleSubmit(({ name, description, icon }) => {
    if (name && !icon) {
      setIsAddingIcon(true);
      return;
    }

    if (!isFormComplete) return;

    if (sectionReference) {
      onEditSection({ name, description, icon });
    } else {
      onPublishSection({ name, description, icon });
    }
  });

  return {
    name,
    error,
    iconURL,
    control,
    onSubmit,
    isLoading,
    selectIcon,
    isAddingIcon,
    isSuccessful,
    setIsAddingIcon,
    isFormComplete: true,
  };
};
