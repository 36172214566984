import { useLaunchDarkly } from "Contexts";
import { webStore } from "Stores/WebStore";
import { LDFlagNames } from "Utilities/types";
import { useGetSearchParams } from "Utilities/useGetSearchParams";

const useShouldCreateSpace = () => {
  const { flags } = useLaunchDarkly();
  const { from } = useGetSearchParams({
    paramList: ["from"],
  });

  const fromLanding = from === "landing-page";

  const readOnlyActivated = flags[LDFlagNames.READ_ONLY_ACTIVATED];

  if (fromLanding && !readOnlyActivated) {
    webStore.setShowCreateSpaceModal(true);
  }
};

export default useShouldCreateSpace;
