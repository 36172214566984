import PillList from "../PillList/PillList";
import { LexicalResult } from "./components/LexicalResult";
import { TopAnswer } from "./components/TopAnswer";
import { KnowledgeResult } from "Components/MasterSearch/types";

interface CopilotSearchResultItemProps {
  title: string;
  icon: React.ReactNode;
  pillBgColor: string;
  pillTextColor: string;
  pillIcon: React.ReactNode;
  searchTypeSection: string;
  mainSectionName: string;
  hero?: string;
  answer?: string;
  resultItem: KnowledgeResult;
  onShareResult?: () => void;
}

const CopilotSearchResultItem = ({
  title,
  resultItem,
  icon,
  pillBgColor,
  pillTextColor,
  pillIcon,
  searchTypeSection,
  mainSectionName,
  hero,
  answer,
  onShareResult,
}: CopilotSearchResultItemProps) => {
  if (hero && answer) {
    // @TODO: remove this in favour of shared component
    return (
      <TopAnswer
        icon={icon}
        text={answer}
        title={title}
        nationalBody={resultItem.nationalBody ?? ""}
        nationalBodyTextColor={resultItem.nationalBodyTextColor || "grey-600"}
        pills={
          <PillList
            pillBgColor={pillBgColor}
            pillTextColor={pillTextColor}
            pillIcon={pillIcon}
            searchTypeSection={searchTypeSection}
            mainSectionName={mainSectionName}
          />
        }
      />
    );
  }

  return (
    <LexicalResult
      item={resultItem}
      icon={icon}
      title={title}
      nationalBody={resultItem.nationalBody}
      mainSectionName={mainSectionName}
      nationalBodyTextColor={resultItem.nationalBodyTextColor || "grey-600"}
      onShareResult={onShareResult}
    />
  );
};

export default CopilotSearchResultItem;
