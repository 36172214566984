import { AnalyticsEvents } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useRunOnMountUnmount } from "Hooks";
import { pageAnalyticsStore, PAGE_ANALYTICS_DISABLED } from "Hooks/usePageAnalytics";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { tabSyncStore } from "Stores/TabSyncStore";
import { isDev, Timeout } from "Utilities/helpers";

const NO_VALUE = "no_value_provided";

const placeHolderObject = {};

export const RouteSync = observer(() => {
  const previousDataRef = useRef<{
    pathname: string;
    eolasPageName: string;
    pageTitle: string;
  } | null>(null);
  const timeoutRef = useRef<Timeout | null>(null);

  const { push } = useHistory();
  const { pathname } = useLocation();

  const eolasPageNameDetails = pageAnalyticsStore.eolasPageNames[pathname] ?? placeHolderObject;

  useEffect(() => {
    const { eolasPageName: setEolasPageName, pageTitle } = eolasPageNameDetails;
    const eolasPageName = setEolasPageName || NO_VALUE;
    if (pathname === "/") {
      // Root path redirects to another page, so ignore:
      return;
    }
    const trackEvents = (isNoNameSet?: boolean) => {
      if (isNoNameSet && isDev()) {
        console.warn("No eolas page name set for analytics for this page. Is this intentional?");
      }
      if (
        previousDataRef.current &&
        previousDataRef.current.pathname !== pathname &&
        previousDataRef.current.eolasPageName !== PAGE_ANALYTICS_DISABLED
      ) {
        trackEvent(AnalyticsEvents.PAGE_LEFT, {
          eolasPageName: previousDataRef.current.eolasPageName,
          userPageName: previousDataRef.current.pageTitle || previousDataRef.current.pathname,
        });
      }
      if (eolasPageName !== PAGE_ANALYTICS_DISABLED) {
        trackEvent(AnalyticsEvents.PAGE_VIEWED, {
          eolasPageName,
          userPageName: pageTitle || pathname,
        });
      }
      previousDataRef.current = { pathname, eolasPageName, pageTitle };
    };

    if (eolasPageNameDetails === placeHolderObject) {
      /**
       * As the eolas page name is set later in the render cycle, we only fire these events after a timeout and can cancel this timeout if the page
       * name comes back in the meantime.
       */
      timeoutRef.current = setTimeout(() => trackEvents(true), 100);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      trackEvents();
    }
  }, [eolasPageNameDetails, pathname]);

  useRunOnMountUnmount({
    onMount: () => {
      tabSyncStore.updateOnMessageCallback({
        type: "routeUpdate",
        callback: (e) => {
          push(e.data.payload.route);
          window.location.reload();
        },
      });
    },
    onUnmount: () => {
      tabSyncStore.clearOnMessageCallback();
    },
  });

  return null;
});
