import { Link, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ClientMainSectionTile, InnerPageWrapper } from "UIKit";
import GridContainer from "UIKit/GridContainer/GridContainer";
import { useIcons } from "Hooks";
import useOrganisationAdminSections from "modules/spaces/data/useOrganisationAdminSections";
import { AppLevelSection, OrganisationLevelSection, PageNames, Space } from "@eolas-medical/core";
import { useSpaceDetailsModal } from "../Space/hooks/useSpaceDetailsModal";
import AccessQRCode from "../Space/components/AccessQRCode/AccessQRCode";
import EditSpaceDetailsWizard from "../Space/components/EditSpaceDetailsWizard/EditSpaceDetailsWizard";
import { modalStore } from "Stores/ModalStore";
import { AdminTitleDetails } from "../Space/components/AdminTitleDetails/AdminTitleDetails";
import { usePageAnalytics } from "Hooks/usePageAnalytics";

const OrganisationAdmin = observer(() => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { getSpaceAdminDashboardIcon } = useIcons();

  usePageAnalytics({ eolasPageName: PageNames.homePage("organisation") + "Admin" });

  const { activeAdminMainSections, handleAnalyticsClick, isLoading } =
    useOrganisationAdminSections();

  const { updatingSpace, handleSpaceUpdated, selectedSpace, updateSpaceSuccess } =
    useSpaceDetailsModal();

  const handleClickShareSpace = () =>
    modalStore.openModal({
      variant: "component",
      Component: <AccessQRCode onClose={() => modalStore.closeModal()} />,
      name: "accessQRCode",
    });

  const handleClickEditSpace = (selectedSpace: Space) =>
    modalStore.openModal({
      variant: "component",
      Component: (
        <EditSpaceDetailsWizard
          onUpdate={handleSpaceUpdated}
          isLoading={updatingSpace}
          isOrgAdminSpace
          selectedSpace={selectedSpace}
          isSuccess={updateSpaceSuccess}
        />
      ),
      name: "accessQRCode",
    });

  if (!selectedSpace) {
    return null;
  }

  return (
    <InnerPageWrapper>
      <AdminTitleDetails
        entity="Organisation"
        onClickEditSpace={() => handleClickEditSpace(selectedSpace)}
        onClickShareSpace={handleClickShareSpace}
      />
      <GridContainer>
        {activeAdminMainSections.map(({ id, route, title, description }) => {
          const Icon = getSpaceAdminDashboardIcon(id);
          if (id === "adminAnalytics" && handleAnalyticsClick) {
            return (
              <div key={id} className="cursor-pointer" onClick={handleAnalyticsClick}>
                <ClientMainSectionTile
                  shouldShowBetaTag
                  id={id as AppLevelSection | OrganisationLevelSection}
                  title={t(title)}
                  description={t(description)}
                  Icon={<Icon className="h-8 w-8 lg:h-14 lg:w-14" />}
                  isLoading={isLoading}
                />
              </div>
            );
          }
          return (
            <Link key={id} to={`${url}/${route}`}>
              <ClientMainSectionTile
                id={id as AppLevelSection | OrganisationLevelSection}
                title={t(title)}
                description={t(description)}
                Icon={<Icon className="h-8 w-8 lg:h-14 lg:w-14" />}
              />
            </Link>
          );
        })}
      </GridContainer>
    </InnerPageWrapper>
  );
});

export default OrganisationAdmin;
