import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";

import { Text, Button, Tag } from "UIKit";
import { appConfig } from "AppTypeConfig";
import { CollapseIcon, EolasIcons } from "Assets/Icons";

import { EolasIcon } from "../../assets/appLogo";
import { customLogos } from "../../assets/customLogos";
import { logout } from "Pages/Authentication/auth.actions";
import { useQueryClient } from "@tanstack/react-query";
import { useCustomTheme } from "../../hooks/useCustomTheme";
import useEolasNavigation from "../../context/useEolasNavigation";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import { useSignoutSpace } from "Hooks/useSignoutSpace";
import { twMerge } from "tailwind-merge";
import useUserDetails from "Hooks/useUserDetails";
import { hasNotSeenFeatureGuide } from "Components/FeatureGuideModal/hooks/useFeatureGuideModal";
import { MeLevelSection } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useSpaceTabName } from "Components/Navigation/hooks/useSpaceTabName";

const buttonClass = "h-10 px-0 rounded-xl w-full";
const meActiveClass = "bg-yellow-50 hover:bg-yellow-100 text-black";
const spaceActiveClass = "bg-blue-50 hover:bg-primary-focus text-black";
const organisationActiveClass = "bg-orange-50 hover:bg-orange-100 text-black";
const knowledgeActiveClass = "bg-green-50 hover:bg-green-100 text-black";

interface NavigationProps {
  drawerExpanded: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleDrawerExpanded: (drawerExpanded: any) => void;
}

export const DesktopNavigation: React.FC<NavigationProps> = observer(
  ({ drawerExpanded, toggleDrawerExpanded }) => {
    const { push } = useHistory();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { customLogo, customName, hasCustomBranding } = useCustomTheme();
    const { isSharedAccount } = useUserDetails();
    const { flags } = useLaunchDarkly();

    const shouldShowNewTagOnMeTab =
      hasNotSeenFeatureGuide(MeLevelSection.myFavourites) &&
      flags[LDFlagNames.MY_FAVOURITES] &&
      !isSharedAccount;

    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
    const { onClearSpace } = useSpacesContext();

    const { signoutSpace } = useSignoutSpace({ shouldPushNewRoute: true, shouldSyncTabs: true });

    const AppIcon = (customLogo && customLogos[customLogo]) || EolasIcon;
    const appName = hasCustomBranding ? customName : appConfig.appName;

    const onLogout = React.useCallback(async () => {
      setIsLoggingOut(true);
      queryClient.clear();
      await logout();
      onClearSpace();
      push("/");
    }, [push, onClearSpace, queryClient]);

    const {
      activeTab,
      hasSpacesTab,
      hasOrganisationTab,
      hasSelectedSpace,
      hasKnowledgeTab,
      goMeTab,
      goToOrganisationTab,
      goToSpaceTab,
      goToSelectSpace,
      goToKnowledgeTab,
    } = useEolasNavigation();

    const { organisationTabName, spacesTabName } = useSpaceTabName();

    return (
      <motion.div
        layout="size"
        className={twMerge(
          "hidden lg:flex flex-col rounded-r-md items-center bg-white p-5 z-10 lg:absolute lg:top-0 lg:h-100vh",
          `${drawerExpanded ? "w-56" : "w-20"}`,
        )}
        transition={{ type: "spring", stiffness: 98, damping: 13 }}
        style={{
          boxShadow: "-3px 14px 54px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        <motion.button
          layout
          onClick={toggleDrawerExpanded}
          animate={{ rotate: drawerExpanded ? 0 : 180 }}
          transition={{ type: "spring", mass: 1, stiffness: 98, damping: 13 }}
          className={`
          bg-grey-200 h-8 w-8 rounded-full flex items-center justify-center
          ${drawerExpanded ? "self-end order-1" : "self-center order-2"}
        `}
        >
          <CollapseIcon className="h-4 w-4" />
        </motion.button>

        <motion.div
          className={`
          flex flex-col items-center w-2/3 self-center space-y-2
          ${drawerExpanded ? "order-2 mt-4" : "order-1 mb-4"}
        `}
        >
          <motion.div layout>
            <AppIcon className="h-10 w-10" />
          </motion.div>

          <AnimatePresence>
            {drawerExpanded ? (
              <motion.span className="text-darkblue-500 font-semibold text-center">
                {appName}
              </motion.span>
            ) : null}
          </AnimatePresence>
        </motion.div>

        <motion.div className="flex flex-col self-stretch space-y-2 mt-8 order-4">
          {!isSharedAccount ? (
            <NavigationItem
              navdrawerExpanded={drawerExpanded}
              testId="navigation_me-tab"
              onClick={goMeTab}
              label={t("navigation_me_tab")}
              isActive={activeTab === "my-profile"}
              activeClassname={meActiveClass}
              icon="PersonIcon"
              shouldShowNewTag={shouldShowNewTagOnMeTab}
            />
          ) : null}
          {!hasSelectedSpace ? (
            <NavigationItem
              navdrawerExpanded={drawerExpanded}
              testId="navigation_department-tab"
              onClick={goToSelectSpace}
              activeClassname={spaceActiveClass}
              isActive={activeTab === "select-space"}
              label={t("navigation_department_tab")}
              icon={"SpacesIcon"}
            />
          ) : null}

          {hasSelectedSpace ? (
            <>
              {hasSpacesTab ? (
                <NavigationItem
                  navdrawerExpanded={drawerExpanded}
                  onClick={goToSpaceTab}
                  testId="navigation_department-tab"
                  isActive={activeTab === "spaces"}
                  label={spacesTabName || t("navigation_department_tab")}
                  activeClassname={spaceActiveClass}
                  icon="SpacesIcon"
                />
              ) : null}

              {hasOrganisationTab ? (
                <NavigationItem
                  navdrawerExpanded={drawerExpanded}
                  onClick={goToOrganisationTab}
                  testId="navigation_hospital-tab"
                  label={organisationTabName || t("navigation_hospital_tab")}
                  isActive={activeTab === "organisation"}
                  activeClassname={organisationActiveClass}
                  icon="OrganisationIcon"
                />
              ) : null}
            </>
          ) : null}

          {hasKnowledgeTab ? (
            <NavigationItem
              navdrawerExpanded={drawerExpanded}
              onClick={goToKnowledgeTab}
              label={t("navigation_knowledge_tab")}
              isActive={activeTab === "knowledge"}
              activeClassname={knowledgeActiveClass}
              testId="navigation_knowledge-tab"
              icon="KnowledgeHubIcon"
            />
          ) : null}
        </motion.div>

        <div className={"flex flex-col order-10 mt-auto self-stretch"}>
          <a target="_blank" rel="noreferrer" href="https://support.eolasmedical.com/en/ ">
            <Button
              color="grey"
              variant="ghost"
              iconLeft="SearchFileIcon"
              className={`${buttonClass} text-grey-700`}
            >
              {drawerExpanded ? (
                <Text level={1} className="ml-4 w-2/3 text-left">
                  {t("navigation_user_guide")}
                </Text>
              ) : null}
            </Button>
          </a>

          <a target="_blank" rel="noreferrer" href={appConfig.termlyPrivacyPolicy}>
            <Button
              color="grey"
              variant="ghost"
              iconLeft="PolicyIcon"
              className={`${buttonClass} text-grey-700 `}
            >
              {drawerExpanded ? (
                <Text level={1} className="ml-4 w-2/3 text-left">
                  {t("navigation_privacy_policy")}
                </Text>
              ) : null}
            </Button>
          </a>

          <Button
            color="grey"
            variant="ghost"
            iconLeft="CookiesIcon"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={() => displayPreferenceModal()}
            className={`${buttonClass} text-grey-700 termly-cookie-preference-button`}
            id="termly-consent-preferences"
          >
            {drawerExpanded ? (
              <Text level={1} className="ml-4 w-2/3 text-left">
                {t("navigation_manage_cookies")}
              </Text>
            ) : null}
          </Button>

          {!isSharedAccount ? (
            <Button
              color="grey"
              variant="outline"
              onClick={signoutSpace}
              iconLeft="ShuffleMonoIcon"
              className="h-10 px-0 mt-6"
              disabled={!hasSelectedSpace}
            >
              {drawerExpanded ? (
                <Text level={1} className="ml-2">
                  {t("navigation_switch_organisation")}
                </Text>
              ) : null}
            </Button>
          ) : null}

          <Button
            onClick={onLogout}
            iconLeft={"LogoutIcon"}
            data-testid="logoutButton"
            className="h-10 px-0 mt-3"
            isLoading={isLoggingOut}
          >
            {drawerExpanded ? (
              <Text level={1} className="ml-4">
                {t("navigation_logout")}
              </Text>
            ) : null}
          </Button>
        </div>
      </motion.div>
    );
  },
);

interface NavigationItemProps {
  label: string;
  testId?: string;
  icon: EolasIcons;
  isActive: boolean;
  navdrawerExpanded: boolean;
  activeClassname?: string;
  onClick(): void;
  shouldShowNewTag?: boolean;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  icon,
  label,
  testId,
  isActive,
  navdrawerExpanded,
  activeClassname,
  onClick,
  shouldShowNewTag,
}: NavigationItemProps) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      data-testid={testId}
      color="grey"
      variant="ghost"
      iconLeft={icon}
      className={`h-10 py-6 rounded-md w-full font-semibold  ${
        isActive ? activeClassname : "bg-transparent"
      }`}
    >
      {navdrawerExpanded ? (
        <Text level={1} className="ml-4 w-2/3 text-left">
          {label}
        </Text>
      ) : null}
      {shouldShowNewTag && navdrawerExpanded ? (
        <Tag className="absolute right-4 bg-yellow-300 text-yellow-700" label={t("general_new")} />
      ) : null}
    </Button>
  );
};
