import { forwardRef } from "react";
import { makeHtmlStringForIFrame } from "./functions";

/**
 * This iFrame is for static HTML content- it is intentionally script free
 */
export const EolasIFrame = forwardRef<
  HTMLIFrameElement,
  {
    internalHtmlContent: string;
    overrideContainerClass?: string;
    overrideCss?: string;
    onLoad?: () => void;
    additionalJs?: string;
  }
>(
  (
    {
      internalHtmlContent,
      overrideContainerClass,
      overrideCss,
      onLoad,
      additionalJs,
    }: {
      internalHtmlContent: string;
      overrideContainerClass?: string;
      overrideCss?: string;
      onLoad?: () => void;
      additionalJs?: string;
    },
    ref,
  ) => {
    return (
      <div>
        <iframe
          ref={ref}
          srcDoc={makeHtmlStringForIFrame({
            htmlContent: internalHtmlContent,
            overrideCss,
            additionalJs,
          })}
          className={overrideContainerClass || "w-full h-fit"}
          sandbox="allow-popups allow-scripts allow-popups-to-escape-sandbox allow-downloads "
          onLoad={onLoad}
        />
      </div>
    );
  },
);
