import { toTitleFormat } from "Utilities/helpers";
import { KnowledgeHubIcon, LocalIcon } from "Assets";
import { SearchResult, SearchResultConfig } from "../types";

const useSearchResultConfig = () => {
  const getSearchResultProps = (result: SearchResult): SearchResultConfig => {
    switch (result.searchTypeSection) {
      case "knowledge":
      case "knowledgeCopilot":
        return {
          searchTypeSection: "masterSearch_knowledge",
          mainSectionName: result.mainSectionName,
          color: "green",
          pillTextColor: "500",
          pillBgColor: "50",
          pillIcon: KnowledgeHubIcon,
          hero: result.hero,
          answer: result.answer,
        };
      case "local":
        return {
          searchTypeSection: "masterSearch_local",
          mainSectionName: toTitleFormat(result.mainSectionName),
          color: "blue",
          pillTextColor: "600",
          pillBgColor: "50",
          pillIcon: LocalIcon,
        };
      default:
        return {
          searchTypeSection: "masterSearch_local",
          mainSectionName: toTitleFormat(result.mainSectionName),
          color: "blue",
          pillTextColor: "600",
          pillBgColor: "50",
          pillIcon: LocalIcon,
        };
    }
  };

  return { getSearchResultProps };
};

export default useSearchResultConfig;
