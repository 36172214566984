export const makeHtmlStringForIFrame = ({
  htmlContent,
  overrideCss,
  additionalJs,
}: {
  htmlContent: string;
  overrideCss?: string;
  additionalJs?: string;
}): string => {
  return `
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
      html, body {
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: 'Nunito', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      }
      body {
        overflow: auto;
        padding: 0.5rem;
      }
      ${overrideCss || ""}
    </style>
    ${htmlContent}
      <script>
        ${additionalJs || ""}
      </script>
  `;
};
