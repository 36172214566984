import {
  AnalyticsEvents,
  ContentItem,
  EolasSearchPayload,
  isEolasMainSectionType,
  sectionStore,
} from "@eolas-medical/core";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { getIdMapToIncludeForContentSearch } from "shared/functions/getIdMapToIncludeForContentSearch";
import { useLocalMobxSearch } from "shared/hooks/useLocalSearch/useLocalMobxSearch";
import { eolasFileNormaliser } from "Utilities/helpers";
import { useGetSpaceOrgInfo } from "../../../hooks/useGetSpaceOrgInfo";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { trackEvent } from "API/Analytics";

// TODO: migrate back to EolasFile (ContentItem should only be for knowledge Content)
const defaultContentItems: ContentItem[] = [];

export const useSpaceOrgSearch = ({ shouldShowAdmin }: { shouldShowAdmin: boolean }) => {
  const { flags, adminStatus, limitedAccess, activeTab, activeSectionId } = useGetSpaceOrgInfo();
  let origin = sectionStore.getSection(activeSectionId).mainSectionType;
  origin = origin === "null" ? "spaceOrgContentRepository" : origin;

  return useLocalMobxSearch({
    analyticsCallback: (text, hits) => {
      if (isEolasMainSectionType(origin) || origin === "spaceOrgContentRepository") {
        trackEvent<EolasSearchPayload>(AnalyticsEvents.MASTER_SEARCH_PERFORMED, {
          searchString: text,
          searchType: "Item",
          origin,
          hitCount: hits,
        });
      }
    },
    data: defaultContentItems,
    mode: "callback",
    searchCallback: async (text: string) => {
      const { map: idsToInclude } = await getIdMapToIncludeForContentSearch({
        isInAdminMode: shouldShowAdmin,
        adminStatus,
        limitedAccess,
        sectionId: activeSectionId,
        mode: "map",
      });
      const results = await localSearchStore.search({
        shouldSearchSpace: activeTab === "spaces",
        shouldSearchOrganisation: activeTab === "organisation",
        idsToInclude,
        term: text,
        ldFlags: flags,
        isInAdminMode: shouldShowAdmin,
      });
      return results.map((item) => {
        const { file } = eolasFileNormaliser(item);
        // TODO: remove the need for this map (should be dealing with EolasFiles directly)
        return mapToContentItem(file);
      });
    },
  });
};
