import { useMutation } from "@tanstack/react-query";
import {
  AnalyticsEvents,
  Space,
  SpaceCreateDto,
  eolasLogger,
  hasProp,
  spacesClient,
} from "@eolas-medical/core";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import { trackEvent } from "API/Analytics";
import { webStore } from "Stores/WebStore";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";

interface CustomError {
  message: string;
}

// A function to determine if the error conforms to CustomError
function isCustomError(error: unknown): error is CustomError {
  return !!error && hasProp(error, "message") && typeof error.message === "string";
}

const useCreateSpace = () => {
  const { onSelectSpace, shouldAllowAccessLoading } = useSelectSpaceActions({
    shouldSyncTabs: true,
  });
  const { showNotification } = useNotifications();
  const { t } = useTranslation();
  const { mutate, isLoading, isSuccess, error } = useMutation(
    async (spaceDto: SpaceCreateDto) => {
      return spacesClient.createSpace(spaceDto);
    },
    {
      onSuccess: async (newSpace: Space) => {
        if (newSpace.id) {
          await onSelectSpace(newSpace.id);
          webStore.setShowCreateSpaceModal(false);
          webStore.setShowPostSpaceCreationModal(true);
          trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_COMPLETED);
        }
      },
      onError: () => {
        eolasLogger.error(new Error("Error creating new space"), { error });
        showNotification({
          type: "error",
          description: t("confirmSpaceModal_createError"),
          autoHideTimeout: 8000,
        });
      },
    },
  );

  const errorCreatingSpace = isCustomError(error) ? error : undefined;

  return {
    createSpace: mutate,
    creatingSpace: isLoading || shouldAllowAccessLoading,
    errorCreatingSpace: errorCreatingSpace?.message ?? "",
    createSpaceSuccess: isSuccess,
  };
};

export default useCreateSpace;
