import { InnerPageWrapper } from "UIKit";

import BnfDrugViewer from "../../../components/BnfDrugViewer/BnfDrugViewer";
import { BnfHeader } from "../../../components/BnfHeader/BnfHeader";
import { MonographContextProvider } from "../../../contexts/ MonographTypeContext";
import { useGetBnfSearchParams } from "../../../hooks/useGetBnfSearchParams";
import useGetMedicationItem from "modules/bnf/data/useGetMedicationItem";
import { AnalyticsEvents, BnfPayload, MonographType, PageNames } from "@eolas-medical/core";
import { useEffect } from "react";
import { trackEvent } from "API/Analytics";

const DrugsDetail = ({ type }: { type: MonographType }) => {
  const { idOrSid, title: searchParamsTitle } = useGetBnfSearchParams();

  const { isLoading, drugItemArray, title } = useGetMedicationItem({ idOrSid, type });

  const titleForAnalytics = searchParamsTitle || title;

  useEffect(() => {
    if (type && titleForAnalytics) {
      trackEvent<BnfPayload>(AnalyticsEvents.BNF_MEDICATION_VIEWED, {
        type,
        name: titleForAnalytics,
      });
    }
  }, [type, titleForAnalytics]);

  return (
    <InnerPageWrapper>
      <BnfHeader
        title={title}
        type={type}
        goBackBehaviourPreference="goBackHistory"
        eolasPageName={PageNames.bnfMonograph}
      />
      <MonographContextProvider type={type}>
        {idOrSid ? (
          <BnfDrugViewer drugItems={drugItemArray} drugItemsLoading={isLoading} idOrSid={idOrSid} />
        ) : null}
      </MonographContextProvider>
    </InnerPageWrapper>
  );
};

export const DrugsDetailAdult = () => <DrugsDetail type="bnf" />;

export const DrugsDetailChild = () => <DrugsDetail type="bnfc" />;
