import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  CompletedContentStatus,
  EolasFile,
  OrganisationLevelSection,
  PageNames,
} from "@eolas-medical/core";

import { useMainSectionVisit, useCompleteFile } from "Hooks";

import { EolasFileList, DeleteFileModal } from "Components";

import { AddButton, InnerPageWrapper, Modal, NavButton, PageTitle } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { AddNewsItemModal, NewsItemViewModal } from "Pages/Spaces/components/NewsItems";
import { useShareFile } from "shared/hooks/useShareFile";
import { ExtractRouteParams } from "Utilities/types";
import { organisationRoutes } from "Pages/Spaces/pages/Organisation/OrganisationRoutes";
import { useSelectCommsPortalItemOnMount } from "../hooks/useSelectCommsPortalItemOnMount";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";

type Props = RouteComponentProps<ExtractRouteParams<typeof organisationRoutes.communicationPortal>>;

export const CommunicationsPortal = observer(({ match: { url } }: Props) => {
  const { t } = useTranslation();
  const { onCompleteFile } = useCompleteFile();

  useMainSectionVisit({ mainSectionId: OrganisationLevelSection.communicationPortal });

  const { isAdmin } = useSpacesContext();
  const [modal, setModal] = useState<React.ReactNode>(null);

  const { spaceSection } = useSpaceSection(OrganisationLevelSection.communicationPortal);

  const { handleOnShareFile, isShareFileEnabled, isShareModalOpen, closeShareModal, shareModal } =
    useShareFile();

  const {
    isFavouriteModalOpen,
    closeFavouriteModal,
    removeFavouriteModal,
    showRemoveFavouriteModal,
  } = useRemoveFavouriteModal();

  const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

  const handleCloseModal = () => {
    if (isShareModalOpen) {
      closeShareModal();
      return;
    }

    if (isFavouriteModalOpen) {
      closeFavouriteModal();
      return;
    }

    setModal(null);
  };

  const onAddModal = () => {
    setModal(
      <AddNewsItemModal
        onCloseModal={handleCloseModal}
        mainSectionType={OrganisationLevelSection.communicationPortal}
      />,
    );
  };

  const onDeleteNewsItem = (newsItem: EolasFile) => {
    setModal(<DeleteFileModal file={newsItem} onCloseModal={handleCloseModal} />);
  };

  const onSelectFile = (newsItem: EolasFile) => {
    // If this condition is true, there are no attachments and we can mark the news item as seen
    if (!newsItem.type || newsItem.type === "communicationPortal") {
      onCompleteFile(newsItem.id, CompletedContentStatus.SEEN);
    }
    setModal(<NewsItemViewModal newsItem={newsItem} />);
  };

  useSelectCommsPortalItemOnMount({ onSelectFile });

  return (
    <>
      <InnerPageWrapper>
        <PageTitle
          title={spaceSection.name ?? t("communicationPortal_title")}
          eolasPageName={PageNames.miniAppFilesList(OrganisationLevelSection.communicationPortal)}
        />
        <NavButton to={url.replace(`/${OrganisationLevelSection.communicationPortal}`, "")} />

        {isAdmin ? (
          <AddButton onClick={onAddModal} data-testid="add-new-file">
            {t(`${OrganisationLevelSection.communicationPortal}_add_new_file`)}
          </AddButton>
        ) : null}

        <EolasFileList
          defaultSort="date"
          draggable={false}
          isAdmin={isAdmin}
          onAddItem={onAddModal}
          onDelete={onDeleteNewsItem}
          onSelectFile={onSelectFile}
          mainSectionId={OrganisationLevelSection.communicationPortal}
          onShareFile={isShareFileEnabled ? handleOnShareFile : undefined}
          showRemoveFavouriteModal={showRemoveFavouriteModal}
        />
      </InnerPageWrapper>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        {modal || shareModal || removeFavouriteModal}
      </Modal>
    </>
  );
});
