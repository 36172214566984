import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

interface ModalHeaderProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  className,
}: ModalHeaderProps) => {
  return (
    <div
      data-testid="modalHeader"
      className={tw(`
        flex flex-col items-center justify-center text-center p-6 sm:p-8 lg:px-10 xl:px-12
        ${className}
      `)}
    >
      {children}
    </div>
  );
};
