import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { OrganisationLevelSection, PageNames, sectionStore } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";
import { SectionList, useSectionManagement } from "Pages/Spaces/components/SectionManagement";
import { AddButton, Modal, NavButton, PageTitle, InnerPageWrapper } from "UIKit";
import useSpacesContext from "modules/spaces/context/useSpacesContext";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

export const LearningManagement: React.FC<RouteComponentProps> = observer(({ match: { url } }) => {
  const { isAdmin } = useSpacesContext();
  const { t } = useTranslation();
  useMainSectionVisit({ mainSectionId: OrganisationLevelSection.learningManagement });

  const { spaceSection } = useSpaceSection(OrganisationLevelSection.learningManagement);

  const { modal, onAddSection, onCloseModal, onEditSection, onDeleteSection } =
    useSectionManagement(spaceSection.id);

  return (
    <>
      <InnerPageWrapper>
        <FileBreadcrumbs
          shouldShowSpaceName={true}
          mainSectionId={
            sectionStore.getChildReferenceByMainSectionType(
              OrganisationLevelSection.learningManagement,
            )?.id
          }
          parentId={spaceSection.id}
        />
        <PageTitle
          title={spaceSection.name ?? t(OrganisationLevelSection.learningManagement)}
          eolasPageName={PageNames.miniAppSectionList(OrganisationLevelSection.learningManagement)}
        />
        <NavButton to={url.replace(`/${OrganisationLevelSection.learningManagement}`, "")} />

        {isAdmin ? (
          <AddButton onClick={onAddSection} data-testid="add-new-subSection">
            {t("addNewSection_button")}
          </AddButton>
        ) : null}

        <SectionList
          isAdmin={isAdmin}
          sectionID={spaceSection.id}
          onEdit={onEditSection}
          onAddItem={onAddSection}
          onDelete={onDeleteSection}
        />

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>
      </InnerPageWrapper>
    </>
  );
});
