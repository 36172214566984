import { Maybe } from "@eolas-medical/core";
import { makeAutoObservable } from "mobx";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

class PageAnalyticsStore {
  constructor() {
    makeAutoObservable(this);
  }

  eolasPageNames: Record<string, { eolasPageName: string; pageTitle: string }> = {};

  setEolasPageDetails = (
    pathname: string,
    details: { eolasPageName: string; pageTitle: string },
  ) => {
    this.eolasPageNames[pathname] = details;
  };

  deleteEolasPageDetails = (pathname: string) => {
    delete this.eolasPageNames[pathname];
  };
}

export const pageAnalyticsStore = new PageAnalyticsStore();

export const PAGE_ANALYTICS_DISABLED = "page_analytics_disabled";

export const usePageAnalytics = ({
  isEnabled = true,
  eolasPageName,
  title,
}: {
  eolasPageName?: Maybe<string>;
  title?: Maybe<string>;
  isEnabled?: boolean;
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    pageAnalyticsStore.setEolasPageDetails(pathname, {
      eolasPageName: isEnabled ? eolasPageName ?? "" : PAGE_ANALYTICS_DISABLED,
      pageTitle: title ?? "",
    });
    return () => {
      pageAnalyticsStore.deleteEolasPageDetails(pathname);
    };
  }, [eolasPageName, isEnabled, pathname, title]);
};
